import Modal from "@mui/material/Modal";
import { ReactComponent as Cross } from "../../assets/cross.svg";
import { useEffect, useState } from "react";
import {
  deepCopy,
  getByFieldValue,
  isEmptyList,
  isNullOrUndefined,
} from "../../utils";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { getTurnaroundDetails, patchTurnaroundProfile } from "../../api";
import { getTurnaroundInfo } from "../../turnaroundUtils";
import { useTranslation } from "react-i18next";
import SelectCrew from "../SelectCrew";
import { debugLog } from "../../logging";

function TaskModal(props) {
  const { t } = useTranslation();

  const { taskInfo, onClose } = props;
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { turnaroundDetails, users, positions, turnarounds } = mainContext;

  const [isProcessing, setIsProcessing] = useState(false);
  const [turnaroundInfo, setTurnaroundInfo] = useState(null);
  const [operationToEdit, setOperationToEdit] = useState(null);
  const [slotsToEdit, setSlotsToEdit] = useState(null);
  const [selectedSlotId, setSelectedSlotId] = useState(null);
  const [selectedUserUuid, setSelectedUserUuid] = useState(null);

  // Fetch the turnaround requested from the taskInfo
  useEffect(() => {
    const turnaroundUuid = !isNullOrUndefined(
      taskInfo?.userTask?.turnaroundUuid
    )
      ? taskInfo.userTask.turnaroundUuid
      : taskInfo?.unassignedTask?.turnaroundUuid;
    if (!isNullOrUndefined(turnaroundUuid)) {
      getTurnaroundDetails(dispatch, {
        uuid: turnaroundUuid,
      });
    }
    // Set the selectedUser if present
    setSelectedUserUuid(taskInfo?.userUuid);

    return () => {
      /** Cleanup **/
      dispatch({
        type: "setTurnaroundDetails",
        value: null,
      });
      setTurnaroundInfo(null);
      setOperationToEdit(null);
      setSlotsToEdit(null);
      setIsProcessing(false);
      setSelectedUserUuid(null);
    };
  }, [dispatch, taskInfo]);

  // Load the turnaround requested from the taskInfo
  useEffect(() => {
    if (!isNullOrUndefined(turnaroundDetails)) {
      setTurnaroundInfo(getTurnaroundInfo(turnaroundDetails));

      const operationUuid = !isNullOrUndefined(
        taskInfo?.userTask?.operationUuid
      )
        ? taskInfo.userTask.operationUuid
        : taskInfo?.unassignedTask?.operationUuid;
      if (!isNullOrUndefined(operationUuid)) {
        const sourceProfile = !isNullOrUndefined(turnaroundDetails?.monitor)
          ? turnaroundDetails.monitor
          : turnaroundDetails.profile;
        const selectedOperation = getByFieldValue(
          sourceProfile?.turnaroundOperations,
          "uuid",
          operationUuid
        );
        if (!isNullOrUndefined(selectedOperation)) {
          // Determine what slots are needed
          const slots = [];
          const requirements = selectedOperation?.turnaroundRequirements;
          if (!isEmptyList(requirements)) {
            for (let i = 0; i < requirements.length; i++) {
              const requirement = requirements[i];
              const assigned = !isEmptyList(requirement.crewAssignments)
                ? requirement.crewAssignments.length
                : 0;
              const missingCount = requirement.quantity - assigned;
              const assignments = [];
              const slotId = requirement.uuid;

              if (!isEmptyList(requirement.crewAssignments)) {
                for (let j = 0; j < requirement.crewAssignments.length; j++) {
                  const crewAssignment = requirement.crewAssignments[j];
                  const user = getByFieldValue(
                    users,
                    "uuid",
                    crewAssignment.userUuid
                  );
                  assignments.push({
                    slotId: slotId,
                    slotAssignmentId: `${requirement.uuid}-${assignments.length}`,
                    requirement: requirement,
                    assignedUser: user,
                  });
                }
              }

              if (missingCount > 0) {
                for (let j = 0; j < missingCount; j++) {
                  assignments.push({
                    slotId: slotId,
                    slotAssignmentId: `${requirement.uuid}-${assignments.length}`,
                    requirement: requirement,
                    assignedUser: null,
                  });
                }
              }
              if (assignments.length > 0) {
                slots.push({
                  slotId: slotId,
                  requirement: requirement,
                  assignments: assignments,
                });
              }
            }
          }
          setOperationToEdit(selectedOperation);
          setSlotsToEdit(slots);
        }
      }
    }
  }, [turnaroundDetails, taskInfo, users]);

  function updateSlots(assignedSlot, user) {
    const slotsToUpdate = deepCopy(slotsToEdit);
    const slotToUpdate = getByFieldValue(
      slotsToUpdate,
      "slotId",
      assignedSlot.slotId
    );
    const slotAssignmentToUpdate = getByFieldValue(
      slotToUpdate.assignments,
      "slotAssignmentId",
      assignedSlot.slotAssignmentId
    );
    if (!isNullOrUndefined(slotAssignmentToUpdate)) {
      slotAssignmentToUpdate.assignedUser = user;
    }
    setSlotsToEdit(slotsToUpdate);
    setSelectedSlotId(null);
  }

  async function handleSaveCrewAssignments() {
    if (isProcessing) return;
    setIsProcessing(true);

    const requirementsToPatch = [];

    for (let i = 0; i < slotsToEdit.length; i++) {
      const slot = slotsToEdit[i];
      const requirement = slot.requirement;

      // Original userAssignments
      const origOperation = getByFieldValue(
        turnaroundInfo?.originalProfile?.turnaroundOperations,
        "uuid",
        operationToEdit.uuid
      );
      const origRequirement = !isNullOrUndefined(
        origOperation?.turnaroundRequirements
      )
        ? getByFieldValue(
            origOperation.turnaroundRequirements,
            "uuid",
            requirement.uuid
          )
        : null;
      const origAssignments = origRequirement?.crewAssignments;
      const origUserAssignments = !isEmptyList(origAssignments)
        ? origAssignments.map((i) => i.userUuid)
        : [];

      const requirementToPatch = {
        uuid: requirement.uuid,
        userUuidsToAdd: [],
        userUuidsToRemove: [],
      };
      const userUuidsToKeep = [];

      // Check assignments to ADD
      const slotAssignments = slot.assignments;
      if (!isEmptyList(slotAssignments)) {
        for (let j = 0; j < slotAssignments.length; j++) {
          const assignment = slotAssignments[j];
          const userUuid = assignment?.assignedUser?.uuid;
          if (!isNullOrUndefined(userUuid)) {
            if (!origUserAssignments.includes(userUuid)) {
              requirementToPatch.userUuidsToAdd.push(userUuid);
            } else {
              userUuidsToKeep.push(userUuid);
            }
          }
        }
      }

      // Check assignments to REMOVE
      if (!isEmptyList(origUserAssignments)) {
        for (let j = 0; j < origUserAssignments.length; j++) {
          const origUserAssignment = origUserAssignments[j];
          if (
            !userUuidsToKeep.includes(origUserAssignment) &&
            !requirementToPatch.userUuidsToAdd.includes(origUserAssignment)
          ) {
            // User was not added or kept therefore remove
            requirementToPatch.userUuidsToRemove.push(origUserAssignment);
          }
        }
      }

      if (
        !isEmptyList(requirementToPatch.userUuidsToAdd) ||
        !isEmptyList(requirementToPatch.userUuidsToRemove)
      ) {
        requirementsToPatch.push(requirementToPatch);
      }
    }
    if (isNullOrUndefined(turnaroundInfo.originalProfile)) {
      // This technically should not happen, but adding a check here
      debugLog(`Turnaround missing profile: ${turnaroundInfo.uuid}`);
    } else {
      const patchRequest = {
        uuid: turnaroundInfo.originalProfile.uuid,
        requirements: requirementsToPatch,
      };
      const result = await patchTurnaroundProfile(dispatch, patchRequest);
      if (result) {
        await getTurnaroundDetails(dispatch, turnaroundInfo);
        dispatch({
          type: "setAlertMessage",
          alertMessage: t("saved_web", {
            name: turnaroundInfo.combinedFlightName,
          }),
        });
      }
    }
    setIsProcessing(false);
    handleCloseModal();

    setIsProcessing(false);
  }

  let modalTitle = null;
  if (
    !isNullOrUndefined(turnaroundInfo?.combinedFlightName) &&
    !isNullOrUndefined(operationToEdit?.name)
  ) {
    modalTitle = `${turnaroundInfo?.combinedFlightName}: ${operationToEdit?.name}`;
  }

  function handleCloseModal() {
    setTurnaroundInfo(null);
    setOperationToEdit(null);
    setSlotsToEdit(null);
    setSelectedSlotId(null);
    onClose();
  }

  // If is readonly, do not allow assigning
  const isReadonly = isNullOrUndefined(turnaroundInfo?.originalProfile);
  return (
    <div>
      <Modal open={!isNullOrUndefined(taskInfo)} onClose={handleCloseModal}>
        <div className="modal">
          {!isNullOrUndefined(turnaroundInfo) &&
            !isNullOrUndefined(operationToEdit) && (
              <>
                <div>
                  <div className="modal-header">
                    <h2>{t("assign_crew")}</h2>
                    <div className="button-icon" onClick={handleCloseModal}>
                      <Cross />
                    </div>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="modal-container">
                    <div className="task-modal-content">
                      <div className="task-modal-header">
                        <div>
                          <div>
                            <h2>{modalTitle}</h2>
                          </div>
                          <div>
                            <span></span>
                          </div>
                        </div>
                        <div>
                          <span>
                            {isReadonly
                              ? t("allocations_readonly_msg")
                              : t("allocations_msg")}
                          </span>
                        </div>
                      </div>
                      {!isEmptyList(slotsToEdit) && (
                        <div className="task-modal-slots-container">
                          {!isEmptyList(slotsToEdit) &&
                            slotsToEdit.map((slot) => (
                              <div
                                className="task-modal-slots"
                                key={slot.slotId}
                              >
                                <div>
                                  {slot.requirement.certification.name} (
                                  {slot.assignments.length})
                                </div>
                                {!isEmptyList(slot.assignments) &&
                                  slot.assignments.map((assignment) => (
                                    <div
                                      className="task-modal-slot"
                                      key={assignment.slotAssignmentId}
                                      data-seleted-user={selectedUserUuid}
                                      data-assigned-user={JSON.stringify(
                                        assignment?.assignedUser
                                      )}
                                    >
                                      <SelectCrew
                                        isActive={
                                          selectedSlotId ===
                                          assignment.slotAssignmentId
                                        }
                                        isProfileEditable={!isReadonly}
                                        isEditable={!isReadonly}
                                        isSelected={
                                          !isNullOrUndefined(
                                            selectedUserUuid
                                          ) &&
                                          selectedUserUuid ===
                                            assignment?.assignedUser?.uuid
                                        }
                                        onClick={() => {
                                          setSelectedSlotId(
                                            assignment.slotAssignmentId
                                          );
                                        }}
                                        onSelect={(selectedUser) => {
                                          updateSlots(
                                            assignment,
                                            selectedUser.user
                                          );
                                        }}
                                        onRemove={() => {
                                          updateSlots(assignment, null);
                                        }}
                                        onCancel={() => {
                                          setSelectedSlotId(null);
                                        }}
                                        requirement={slot.requirement}
                                        assignments={slot.assignments}
                                        assignedUser={assignment.assignedUser}
                                        users={users}
                                        positions={positions}
                                        turnarounds={turnarounds}
                                      />
                                    </div>
                                  ))}
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="modal-footer">
                    <div>
                      <button className="secondary" onClick={handleCloseModal}>
                        {t("cancel")}
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={handleSaveCrewAssignments}
                        disabled={isProcessing || isReadonly}
                      >
                        {t("save")}
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
        </div>
      </Modal>
    </div>
  );
}

export default TaskModal;
