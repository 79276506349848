import { useMemo } from "react";
import { isEmptyList, isNullOrUndefined, sortByField } from "../../utils";
import {
  getCrewRecordsForCrewAssignments,
  getGseRecordsByGseType,
} from "../../turnaroundUtils";
import { useTranslation } from "react-i18next";

function TurnaroundModuleSummary(props) {
  const { t } = useTranslation();
  const { selectedTurnaroundInfo, onSelectOperation } = props;

  const operationsToRender = useMemo(() => {
    const operationsList = [];
    const turnaroundProfile = !isNullOrUndefined(
      selectedTurnaroundInfo?.originalMonitor
    )
      ? selectedTurnaroundInfo?.originalMonitor
      : selectedTurnaroundInfo?.originalProfile;
    if (!isEmptyList(turnaroundProfile?.turnaroundOperations)) {
      const operations = turnaroundProfile.turnaroundOperations;
      sortByField(operations, "type.name");
      if (!isEmptyList(operations)) {
        for (let i = 0; i < operations.length; i++) {
          const operation = operations[i];
          const requirementSummaryList = [];
          const requirements = operation.turnaroundRequirements;

          // GSE records to show
          const gseRecordsByGseType = getGseRecordsByGseType(
            operation?.gseRecords
          );

          for (let j = 0; j < requirements.length; j++) {
            const requirement = requirements[j];
            const groundVehicleTypeUuid =
              requirement.certification.groundVehicleTypeUuid;

            const isGseRequirement = !isNullOrUndefined(groundVehicleTypeUuid);
            const gseRecordsForVehicleType =
              isGseRequirement && groundVehicleTypeUuid in gseRecordsByGseType
                ? gseRecordsByGseType[groundVehicleTypeUuid]
                : [];

            // Get userRecords for the crewAssignments
            const crewRecordsForCrewAssignments =
              getCrewRecordsForCrewAssignments(
                operation?.userRecords,
                requirement.crewAssignments
              );

            const summaryRow = {
              uuid: requirement.uuid,
              name: requirement.certification.name,
              gseQuantity: isGseRequirement ? requirement.quantity : 0,
              crewQuantity: requirement.quantity,
              gseRecords: isGseRequirement ? gseRecordsForVehicleType : [],
              crewRecords: crewRecordsForCrewAssignments,
              crewAssignments: requirement.crewAssignments,
            };
            if (summaryRow.gseQuantity > 0 || summaryRow.crewQuantity > 0) {
              requirementSummaryList.push(summaryRow);
            }
          }

          operationsList.push({
            uuid: operation.uuid,
            name: operation.type.name,
            requirements: requirementSummaryList,
          });
        }
      }
    }
    return operationsList;
  }, [selectedTurnaroundInfo]);

  return (
    <div className="turnarounds-module-summary">
      <div className="turnarounds-module-summary-header">
        <div>
          <div>{t("operation")}</div>
        </div>
        <div>
          <div>{t("resources")}</div>
        </div>
        <div>
          <div>{t("gse")}</div>
        </div>
        <div>
          <div>{t("crew")}</div>
        </div>
      </div>
      {operationsToRender &&
        !isEmptyList(operationsToRender) &&
        operationsToRender.map((operation) => (
          <div
            className="turnarounds-module-summary-grouping"
            data-operation-uuid={operation.uuid}
            onClick={() => onSelectOperation(operation.uuid)}
            key={operation.uuid}
          >
            {!isEmptyList(operation.requirements) &&
              operation.requirements.map((requirement, idx) => (
                <div
                  className="turnarounds-module-summary-row"
                  key={requirement.uuid}
                >
                  <div>{idx === 0 ? operation.name : ""} </div>
                  <div>{requirement.name}</div>
                  <div>
                    {requirement.gseQuantity > 0 && (
                      <div className="quantity-totals left">
                        <div className="quantity-totals-row">
                          <div>{t("required")}:</div>
                          <div>{requirement.gseQuantity}</div>
                        </div>
                        <div className="quantity-totals-row">
                          <div>{t("arrived")}:</div>
                          <div>{requirement.gseRecords.length}</div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    {requirement.crewQuantity > 0 && (
                      <div className="quantity-totals left">
                        <div className="quantity-totals-row">
                          <div>{t("required")}:</div>
                          <div>{requirement.crewQuantity}</div>
                        </div>
                        <div className="quantity-totals-row">
                          <div>{t("assigned")}:</div>
                          <div>{requirement.crewAssignments.length}</div>
                        </div>
                        <div className="quantity-totals-row">
                          <div>{t("arrived")}:</div>
                          <div>{requirement.crewRecords.length}</div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        ))}
    </div>
  );
}

export default TurnaroundModuleSummary;
