import { ReactComponent as TurnaroundCircle } from "../../assets/turnaround-circle.svg";
import { ReactComponent as TurnaroundCircleDark } from "../../assets/turnaround-circle-dark.svg";
import { ReactComponent as GroupCircle } from "../../assets/group-circle.svg";
import { ReactComponent as GroupCircleDark } from "../../assets/group-circle-dark.svg";
import {
  formatDate,
  formatTime,
  isBlank,
  isNullOrUndefined,
} from "../../utils";
import CrewInfo from "../CrewInfo";
import { UserPicSize, UserPicTheme } from "../UserBadge/UserPic";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";

function ChannelInfo(props) {
  const { t } = useTranslation();
  const { channelInfo, isSelected, isHeader, timezone } = props;
  const mToday = moment().tz(timezone).startOf("day");
  const todayDateString = formatDate(mToday.toDate(), timezone);
  const chatType = channelInfo?.isTurnaround ? t("turnaround_chat") : "";

  let details = (
    <div className="channel-info-details">
      <div>{chatType}</div>
      <div></div>
    </div>
  );
  if (!isBlank(channelInfo?.lastMessagePreview)) {
    const mMessageTime = moment(channelInfo.lastMessageTime).tz(timezone);
    const messageTimeDateString = formatDate(mMessageTime.toDate(), timezone);
    const timestamp =
      todayDateString === messageTimeDateString
        ? formatTime(mMessageTime.toDate(), timezone)
        : messageTimeDateString;

    details = (
      <div className="channel-info-details">
        <div>{channelInfo.lastMessagePreview}</div>
        <div>{timestamp}</div>
      </div>
    );
  }

  return (
    <div className={`channel-info${isHeader ? " fade-in" : ""}`}>
      {channelInfo?.isTurnaround && (
        <div className="channel-info-badge">
          <div>
            {!isSelected && <TurnaroundCircleDark />}
            {isSelected && <TurnaroundCircle />}
          </div>
          <div className="channel-info-badge-name">
            <div>{channelInfo.name}</div>
            {!isHeader && <div>{details}</div>}
          </div>
        </div>
      )}

      {channelInfo?.isGroup && (
        <div className="channel-info-badge">
          <div>
            {!isSelected && <GroupCircleDark />}
            {isSelected && <GroupCircle />}
          </div>
          <div className="channel-info-badge-name">
            <div>{channelInfo.name}</div>
            {!isHeader && <div>{details}</div>}
          </div>
        </div>
      )}
      {channelInfo?.isDirect &&
        !isNullOrUndefined(channelInfo?.chatUserInfo) && (
          <CrewInfo
            user={channelInfo.chatUserInfo?.user}
            details={!isHeader ? details : null}
            mapStatus={channelInfo.chatUserInfo?.mapStatus}
            userPicSize={UserPicSize.LARGE}
            userPicTheme={isSelected ? UserPicTheme.DEFAULT : UserPicTheme.DARK}
          />
        )}
    </div>
  );
}
export default ChannelInfo;
