import { useTranslation } from "react-i18next";
import { EMPTY_TIME } from "../../constants";
import { formatTime, isNullOrUndefined } from "../../utils";

function GseInfo(props) {
  const { t } = useTranslation();

  const { serviceRecord, placeholder, timezone } = props;

  const startTime = !isNullOrUndefined(serviceRecord?.startTime)
    ? formatTime(serviceRecord?.startTime, timezone)
    : EMPTY_TIME;
  const endTime = !isNullOrUndefined(serviceRecord?.endTime)
    ? formatTime(serviceRecord?.endTime, timezone)
    : EMPTY_TIME;

  const isInProgress = !isNullOrUndefined(serviceRecord)
    ? !isNullOrUndefined(serviceRecord.startTime) &&
      isNullOrUndefined(serviceRecord.endTime)
    : false;
  const isCompleted = !isNullOrUndefined(serviceRecord)
    ? !isNullOrUndefined(serviceRecord.startTime) &&
      !isNullOrUndefined(serviceRecord.endTime)
    : false;

  return (
    <div
      className={`gse-info${!isNullOrUndefined(placeholder) ? " empty" : ""}`}
    >
      <div>
        <div>
          <div
            className={`gse-status${isInProgress ? " in-progress" : ""}${
              isCompleted ? " completed" : ""
            }`}
          >
            <div></div>
          </div>
        </div>
      </div>
      <div>
        <div className="gse-info-name">
          {!isNullOrUndefined(serviceRecord) &&
            serviceRecord.groundVehicle.name}
          {!isNullOrUndefined(placeholder) && placeholder.name}
        </div>
        <div className="gse-info-type">
          {!isNullOrUndefined(serviceRecord) &&
            serviceRecord.groundVehicle.gseType.name}
          {!isNullOrUndefined(placeholder) && placeholder.type}
        </div>
        {!isNullOrUndefined(serviceRecord) && (
          <>
            <div className="gse-info-timestamp">
              <span>
                {t("arrived")}: {startTime}
              </span>
            </div>
            <div className="gse-info-timestamp">
              <span>
                {t("departed")}: {endTime}
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default GseInfo;
