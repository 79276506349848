import "./styles.css";
import { useTranslation } from "react-i18next";
import { Modal } from "@mui/material";
import { isEmptyList, isNullOrUndefined } from "../../utils";
import { ReactComponent as Cross } from "../../assets/cross.svg";

// Component for displaying multiple error messages
function AlertMessageModal(props) {
  const { t } = useTranslation();

  const { alertMessages, onClose } = props;

  function handleCloseModal() {
    onClose();
  }

  let modalClass = "";
  if (alertMessages?.length < 5) {
    modalClass = "modal-small";
  } else if (alertMessages?.length < 20) {
    modalClass = "modal-medium";
  }
  return (
    <div>
      <Modal
        open={!isNullOrUndefined(alertMessages)}
        onClose={handleCloseModal}
      >
        <div className={`modal ${modalClass}`}>
          {!isNullOrUndefined(alertMessages) && (
            <>
              <div>
                <div className="modal-header">
                  <h2>
                    {t("errors")} ({alertMessages.length})
                  </h2>
                  <div className="button-icon" onClick={handleCloseModal}>
                    <Cross />
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="modal-container">
                  <div className="alert-messages-rows">
                    {!isEmptyList(alertMessages) &&
                      alertMessages.map((item, idx) => (
                        <div className="alert-messages-row" key={idx}>
                          {item}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div>
                <div className="modal-footer">
                  <div></div>
                  <div>
                    <button className="secondary" onClick={handleCloseModal}>
                      {t("close")}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}
export default AlertMessageModal;
