import { useTranslation } from "react-i18next";
import { formatTime, isEmptyList, isNullOrUndefined } from "../../utils";
import { EMPTY_TIME } from "../../constants";
import { useMemo } from "react";

function TurnaroundModuleStats(props) {
  const { t } = useTranslation();
  const { selectedOperation, timezone } = props;

  const stats = useMemo(() => {
    const statsValues = {
      allocatedCrew: 0,
      requiredCrew: 0,
      allocatedGse: 0,
      requiredGse: 0,
    };
    const requirements = selectedOperation?.turnaroundRequirements;
    if (!isEmptyList(requirements)) {
      for (let i = 0; i < requirements.length; i++) {
        const requirement = requirements[i];
        const qty = requirement.quantity;
        if (
          !isNullOrUndefined(requirement?.certification?.groundVehicleTypeUuid)
        ) {
          // Quantity applies to both GSE and Crew
          statsValues.requiredGse = statsValues.requiredGse + qty;
          statsValues.requiredCrew = statsValues.requiredGse + qty;
        } else {
          statsValues.requiredCrew = statsValues.requiredGse + qty;
        }
        // For Crew, use crewAssignments as the allocated value
        if (!isNullOrUndefined(requirement.crewAssignments)) {
          statsValues.allocatedCrew =
            statsValues.requiredGse + requirement.crewAssignments.length;
        }
        // For GSE, use gseRecords as the allocated value
        if (!isNullOrUndefined(requirement.gseRecords)) {
          statsValues.allocatedGse =
            statsValues.allocatedGse + requirement.gseRecords.length;
        }
      }
    }

    return statsValues;
  }, [selectedOperation]);

  return (
    <div className="stats">
      <div className="stat">
        <div>
          <div>
            <span>{t("required_crew")}</span>
          </div>
          <div>{`${stats?.allocatedCrew}/${stats?.requiredCrew}`}</div>
        </div>
      </div>
      <div className="stat">
        <div>
          <div>
            <span>{t("required_gse")}</span>
          </div>
          <div>{`${stats?.allocatedGse}/${stats?.requiredGse}`}</div>
        </div>
      </div>
      <div className="divider">
        <div></div>
      </div>

      <div className="stat">
        <div>
          <div>
            <span>
              {!isNullOrUndefined(selectedOperation.initiatedTime)
                ? t("initiated")
                : t("expected_start")}
            </span>
          </div>
          <div>
            {!isNullOrUndefined(selectedOperation.initiatedTime)
              ? formatTime(selectedOperation.initiatedTime, timezone)
              : formatTime(selectedOperation.expectedStartTime, timezone)}
          </div>
        </div>
      </div>
      <div className="stat">
        <div>
          <div>
            <span>{t("completed")}</span>
          </div>
          <div>
            {!isNullOrUndefined(selectedOperation.completedTime)
              ? formatTime(selectedOperation.completedTime, timezone)
              : EMPTY_TIME}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TurnaroundModuleStats;
