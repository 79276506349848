import { useMainContext } from "../../MainContext";

function ChatIndicator() {
  const mainContext = useMainContext();
  const { unreadChatChannels } = mainContext;

  const showIndicator = unreadChatChannels > 0;
  return (
    <>
      {showIndicator && (
        <div className="status-indicator">
          <div>{unreadChatChannels}</div>
        </div>
      )}
    </>
  );
}

export default ChatIndicator;
