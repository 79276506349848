import { useReducer } from "react";
import { ChatContext, ChatDispatchContext } from "./ChatContext";
import { deepCopy } from "./utils";

export const initialState = {
  selectedAttachment: null,
  chatSummary: [],
  chatSummaryLoading: false,
  chatChannel: null,
  chatChannelLoading: null,
  chatChannelLastRead: false,
  chatChannelLastReadSaving: false,
  chatChannelMessage: null,
  chatChannelMessageSaving: false,
  chatChannelMembers: null,
  chatChannelMembersSaving: false,
  createChatChannel: null,
  createChatChannelSaving: false,
  chatAttachmentUpload: null,
  chatAttachmentUploadLoading: false,
};

export function commsReducer(state, action) {
  switch (action.type) {
    case "setSelectedAttachment":
      return {
        ...state,
        selectedAttachment: action.value,
      };
    case "setChatSummary":
      return {
        ...state,
        chatSummary: action.value,
      };
    case "setChatSummaryLoading":
      return {
        ...state,
        chatSummaryLoading: action.value,
      };
    case "setChatChannel":
      return {
        ...state,
        chatChannel: action.value,
      };
    case "setChatChannelLoading":
      return {
        ...state,
        chatChannelLoading: action.value,
      };
    case "setChatChannelLastRead":
      return {
        ...state,
        chatChannelLastRead: action.value,
      };
    case "setChatChannelLastReadSaving":
      return {
        ...state,
        chatChannelLastReadSaving: action.value,
      };
    case "setChatChannelMessage":
      return {
        ...state,
        chatChannelMessage: action.value,
      };
    case "setChatChannelMessageSaving":
      return {
        ...state,
        chatChannelMessageSaving: action.value,
      };
    case "setChatChannelMembers":
      return {
        ...state,
        chatChannelMembers: action.value,
      };
    case "setChatChannelMembersSaving":
      return {
        ...state,
        chatChannelMembersSaving: action.value,
      };
    case "setCreateChatChannel":
      return {
        ...state,
        createChatChannel: action.value,
      };
    case "setCreateChatChannelSaving":
      return {
        ...state,
        createChatChannelSaving: action.value,
      };
    case "setChatAttachmentUpload":
      return {
        ...state,
        chatAttachmentUpload: action.value,
      };
    case "setChatAttachmentUploadLoading":
      return {
        ...state,
        chatAttachmentUploadLoading: action.value,
      };

    case "setTempChannelMessage": {
      const date = new Date();

      const message = {
        id: `temp-message-${date.getTime()}`,
        content: {
          text: action.value,
          attachments: [],
        },
        sentBy: action.sentBy,
        sentAt: date.toISOString(),
        isTemp: true,
      };

      const updatedChannel = deepCopy(state.chatChannel);
      updatedChannel.messages.push(message);

      return {
        ...state,
        chatChannel: updatedChannel,
      };
    }
    case "setCurrentUser":
    case "setAnalyticsEvent":
    case "setGlobalError": {
      //No-op
      return {
        ...state,
      };
    }

    default:
      console.log(`Unknown action: ${action.type}`);
      //throw Error(`Unknown action: ${action.type}`);
      return {
        ...state,
      };
  }
}

export default function ChatProvider({ children }) {
  const [main, dispatch] = useReducer(commsReducer, initialState);
  return (
    <ChatContext.Provider value={main}>
      <ChatDispatchContext.Provider value={dispatch}>
        {children}
      </ChatDispatchContext.Provider>
    </ChatContext.Provider>
  );
}
