import { formatHoursMins, formatTime, isNullOrUndefined } from "../../utils";
import { TurnaroundStatus } from "../../turnaroundUtils";

export const TurnaroundsModuleColumns = {
  TurnaroundName: {
    id: "turnaround_name",
    displayName: "turnaround",
    formatter: (item) => {
      return (
        <div className="datatable-turnaround-info">
          <div>{item?.combinedFlightName}</div>
          <div>
            <span>{item.registration}</span>
          </div>
        </div>
      );
    },
    sortValue: (item) => `${item.combinedFlightName}`,
  },
  Registration: {
    id: "registration",
    displayName: "registration",
    formatter: (item) => {
      return item?.registration;
    },
    sortValue: (item) => `${item.registration}`,
  },
  Landing: {
    id: "landing",
    displayName: "landing_non_cap",
    formatter: (item) => {
      return formatTime(item?.landing, item?.timezone);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.landing) ? new Date(item?.landing) : null,
  },
  GateIn: {
    id: "gate_in",
    displayName: "gate_in_non_cap",
    formatter: (item) => {
      return formatTime(item?.gateIn, item?.timezone);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.gateIn) ? new Date(item?.gateIn) : null,
  },
  GateOut: {
    id: "gate_out",
    displayName: "gate_out_non_cap",
    formatter: (item) => {
      return formatTime(item?.gateOut, item?.timezone);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.gateOut) ? new Date(item?.gateOut) : null,
  },
  Takeoff: {
    id: "takeoff",
    displayName: "takeoff_non_cap",
    formatter: (item) => {
      return formatTime(item?.takeoff, item?.timezone);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.takeoff) ? new Date(item?.takeoff) : null,
  },
  TotalGate: {
    id: "total_gate_time",
    displayName: "total_gate_time",
    formatter: (item) => {
      return formatHoursMins(item?.gateTimeMinutes);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.gateTimeMinutes) ? item?.gateTimeMinutes : 0,
  },
  TotalGround: {
    id: "total_ground_time",
    displayName: "total_ground_time",
    formatter: (item) => {
      return formatHoursMins(item?.groundTimeMinutes);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.groundTimeMinutes) ? item?.groundTimeMinutes : 0,
  },
  Status: {
    id: "status",
    displayName: "operation_status",
    formatter: (item) => {
      let statusClass = "active";
      const statusName = item?.statusDisplayName;
      return (
        <div className={`datatable-turnaround-status ${statusClass}`}>
          <div>{statusName}</div>
        </div>
      );
    },
    sortValue: (item) => {
      if (item.statusCode === TurnaroundStatus.TurnaroundInProgress) {
        return 0;
      } else if (item.statusCode === TurnaroundStatus.TurnaroundComplete) {
        return 3;
      } else if (item.statusCode === TurnaroundStatus.TurnaroundPlanned) {
        return 1;
      } else if (item.statusCode === TurnaroundStatus.TurnaroundNotConfigured) {
        return 2;
      }
    },
  },
};
