import { useTranslation } from "react-i18next";
import {
  formatTime,
  formatTimeRange,
  getByFieldValue,
  getTimezoneFromUser,
  isBlank,
  isEmptyList,
  isNullOrUndefined,
  sortByField,
} from "../../utils";
import "./styles.css";
import { selectInputFix } from "../../styleUtils";
import moment from "moment-timezone";
import { useEffect, useMemo, useRef, useState } from "react";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import {
  useTasksOverviewContext,
  useTasksOverviewDispatchContext,
} from "../../TasksOverviewContext";
import {
  getOperationTypes,
  getTurnaroundsSummary,
  getUsers,
  getDepartments,
} from "../../api";
import { getDepartmentRoster } from "../../tasksOverviewApi";
import { POLLING_INTERVALS } from "../../constants";
import { getUserInfo } from "../../userUtils";
import { Fade, Tooltip } from "@mui/material";
import { tooltipStyles } from "../../styleUtils";
import CrewInfo from "../CrewInfo";
import { getTaskInfo } from "./utils";
import TaskModal from "./TaskModal";
import CrewTaskModal from "./CrewTaskModal";

// NOTE: This is renamed to Live Roster in the webapp
function LiveRoster() {
  const { t } = useTranslation();

  const dispatch = useMainDispatchContext();
  const tasksOverviewDispatch = useTasksOverviewDispatchContext();

  const mainContext = useMainContext();
  const {
    currentUser,
    turnaroundsSummary,
    users,
    operationTypes,
    positions,
    departments,
    isRefreshPaused,
  } = mainContext;
  const tasksOverviewContext = useTasksOverviewContext();
  const { departmentRoster } = tasksOverviewContext;

  const airportTimezone = getTimezoneFromUser(currentUser);
  const mNow = moment().tz(airportTimezone);

  const [refreshOverviewRequested, setRefreshOverviewRequested] =
    useState(false);
  const [timelineRanges, setTimelineRanges] = useState({
    mRangeStart: moment(mNow).startOf("hour").add(-2, "hour"),
    mRangeEnd: moment(mNow).startOf("hour").add(10, "hour"),
  });

  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [taskInfo, setTaskInfo] = useState(null);
  const [crewInfo, setCrewInfo] = useState(null);

  // 1hr increments
  const timeBuckets = [];
  const timeInterval = moment(timelineRanges.mRangeStart);
  while (!timeInterval.isAfter(timelineRanges.mRangeEnd)) {
    if (timeInterval.isAfter(timelineRanges.mRangeStart)) {
      timeBuckets.push(moment(timeInterval));
    }
    timeInterval.add(1, "hour");
  }

  useEffect(() => {
    // Interval for refreshing the data within the timeframe
    const intervals = [];

    if (POLLING_INTERVALS.LIVE_ROSTER > 0) {
      const interval = setInterval(() => {
        setRefreshOverviewRequested(true);
      }, POLLING_INTERVALS.LIVE_ROSTER);
      intervals.push(interval);
    }
    return () => {
      for (let i = 0; i < intervals.length; i++) {
        clearInterval(intervals[i]);
      }
    };
  }, []);

  useEffect(() => {
    if (!refreshOverviewRequested) return () => {};
    const mNowUpdated = moment().tz(airportTimezone);
    setTimelineRanges({
      mRangeStart: moment(mNowUpdated).startOf("hour").add(-2, "hour"),
      mRangeEnd: moment(mNowUpdated).startOf("hour").add(10, "hour"),
    });

    setRefreshOverviewRequested(false);
  }, [refreshOverviewRequested, airportTimezone]);

  useEffect(() => {
    // Load supporting data here
    if (isNullOrUndefined(timelineRanges)) return () => {};
    if (isRefreshPaused) return () => {};
    getUsers(dispatch);
    getOperationTypes(dispatch);
    getDepartments(dispatch);
    getTurnaroundsSummary(dispatch);
  }, [isRefreshPaused, dispatch, timelineRanges]);

  useEffect(() => {
    sortByField(departments, "name");
    // if list contains a default department, move it to the front
    const defaultDepartment = getByFieldValue(departments, "name", "Default");
    if (!isNullOrUndefined(defaultDepartment)) {
      const defaultDepartmentIndex = departments.indexOf(defaultDepartment);
      if (defaultDepartmentIndex > 0) {
        departments.splice(defaultDepartmentIndex, 1);
        departments.unshift(defaultDepartment);
      }
    }
  }, [departments]);

  useEffect(() => {
    if (isEmptyList(departments)) return () => {};
    // If no department is selected, use the first one from the list
    const departmentUuid = selectedDepartment || departments[0]?.uuid;
    if (!isBlank(departmentUuid)) {
      getDepartmentRoster(tasksOverviewDispatch, departmentUuid);
    }
  }, [departments, selectedDepartment, tasksOverviewDispatch]);

  const containerRef = useRef(null);
  const markersRef = useRef(null);
  const gridlinesRef = useRef(null);

  function getTimeAsPixels(minutes) {
    const containerEl = gridlinesRef.current;
    const containerWidth = !isNullOrUndefined(containerEl)
      ? containerEl.getBoundingClientRect().width - 2
      : 0;
    const totalWidth = containerWidth;

    const totalDuration = timelineRanges.mRangeEnd.diff(
      timelineRanges.mRangeStart,
      "minutes"
    );
    return minutes * (totalWidth / totalDuration);
  }

  function getTimelineMarkerStyle(mMakerTime) {
    if (isNullOrUndefined(mMakerTime)) return {};
    const durationFromRangeStart = mMakerTime.diff(
      timelineRanges.mRangeStart,
      "minutes"
    );
    const timelineLeft = getTimeAsPixels(durationFromRangeStart);
    return {
      left: `${timelineLeft}px`,
    };
  }

  function getTimelineBarStyle(taskInfo) {
    const mTaskStart = moment(taskInfo.expectedStartTime).tz(airportTimezone);
    const mTaskEnd = moment(taskInfo.expectedEndTime).tz(airportTimezone);
    const isBeforeRangeStart = mTaskStart.isBefore(timelineRanges.mRangeStart);
    const isAfterRangeEnd = mTaskEnd.isAfter(timelineRanges.mRangeEnd);
    const durationFromRangeStart = !isBeforeRangeStart
      ? mTaskStart.diff(timelineRanges.mRangeStart, "minutes")
      : 0;

    // Amount of the task that is trimmed off from the front
    const trimmedDuration = isBeforeRangeStart
      ? timelineRanges.mRangeStart.diff(mTaskStart, "minutes")
      : 0;
    const durationToRangeEnd = !isNullOrUndefined(mTaskStart)
      ? timelineRanges.mRangeEnd.diff(mTaskStart, "minutes")
      : 0;
    const adjustedDuration = isBeforeRangeStart
      ? taskInfo.duration - trimmedDuration
      : taskInfo.duration;
    const duration = isAfterRangeEnd ? durationToRangeEnd : adjustedDuration;
    const timelineWidth = getTimeAsPixels(duration);
    const timelineLeft = getTimeAsPixels(durationFromRangeStart);
    let style = {
      left: `${timelineLeft}px`,
    };
    if (duration > 0) {
      style.width = `${timelineWidth}px`;
    }
    if (isBeforeRangeStart) {
      style.borderTopLeftRadius = "0px";
      style.borderBottomLeftRadius = "0px";
    }
    if (isAfterRangeEnd) {
      style.borderTopRightRadius = "0px";
      style.borderBottomRightRadius = "0px";
    }
    return style;
  }

  // Main function to calculate the gantt timelines
  const [taskInfoList, unassignedTaskInfoList] = useMemo(() => {
    const tasksToRender = [];
    const unassignedTasksToRender = [];
    if (
      !isNullOrUndefined(departmentRoster) &&
      !isEmptyList(users) &&
      !isEmptyList(turnaroundsSummary) &&
      !isEmptyList(operationTypes)
    ) {
      // Process department roster users and their tasks/shifts
      if (!isEmptyList(departmentRoster?.users)) {
        for (let i = 0; i < departmentRoster.users.length; i++) {
          const rosterUser = departmentRoster.users[i];
          const mTaskStart = moment(rosterUser.expectedStartTime).tz(
            airportTimezone
          );

          // Process user shifts
          const userShifts = [];
          if (!isEmptyList(rosterUser.shifts)) {
            for (let j = 0; j < rosterUser.shifts.length; j++) {
              const shift = rosterUser.shifts[j];
              const mStart = moment(shift.startTime).startOf("minute");
              const mEnd = moment(shift.endTime).startOf("minute");
              userShifts.push({
                expectedStartTime: shift.startTime,
                expectedEndTime: shift.endTime,
                duration: mEnd.diff(mStart, "minutes"),
                departmentName: shift.departmentName,
              });
            }
          }

          // Process user tasks
          const userTasks = [];
          if (!isEmptyList(rosterUser.tasks)) {
            for (let j = 0; j < rosterUser.tasks.length; j++) {
              const userTask = rosterUser.tasks[j];
              const taskInfo = getTaskInfo(
                userTask,
                operationTypes,
                turnaroundsSummary
              );
              if (!isNullOrUndefined(taskInfo)) {
                userTasks.push(taskInfo);
              }
            }
          }

          if (
            (!isEmptyList(userTasks) || !isEmptyList(userShifts)) &&
            mTaskStart.isBefore(timelineRanges.mRangeEnd)
          ) {
            const user = getByFieldValue(users, "uuid", rosterUser.uuid);
            const userInfo = !isNullOrUndefined(user)
              ? getUserInfo(user, turnaroundsSummary, positions)
              : null;

            tasksToRender.push({
              userUuid: rosterUser.uuid,
              userInfo: userInfo,
              tasks: rosterUser.tasks,
              userTasks: userTasks,
              userCrewShifts: userShifts,
            });
          }
        }
        sortByField(tasksToRender, "userInfo.fullName");
      }

      // Process unassigned tasks from department roster
      if (!isEmptyList(departmentRoster?.unassignedTasks)) {
        for (let i = 0; i < departmentRoster.unassignedTasks.length; i++) {
          const unassignedTask = departmentRoster.unassignedTasks[i];
          const mTaskStart = moment(unassignedTask.expectedStartTime).tz(
            airportTimezone
          );
          const taskInfo = getTaskInfo(
            unassignedTask,
            operationTypes,
            turnaroundsSummary
          );
          if (
            !isNullOrUndefined(taskInfo) &&
            mTaskStart.isBefore(timelineRanges.mRangeEnd)
          ) {
            unassignedTasksToRender.push(taskInfo);
          }
        }
        sortByField(unassignedTasksToRender, "expectedStartTime", [
          "operationType.name",
        ]);
      }
    }
    return [tasksToRender, unassignedTasksToRender];
  }, [
    departmentRoster,
    users,
    turnaroundsSummary,
    operationTypes,
    positions,
    airportTimezone,
    timelineRanges,
  ]);

  // Need to correct the vertical position of the label so that it doesn't get covered by header
  function handleContentScroll() {
    const scrollEl = containerRef.current;
    const markersEl = markersRef.current;
    if (isNullOrUndefined(scrollEl) || isNullOrUndefined(markersEl)) return;

    const scrollTop = scrollEl.scrollTop;
    markersEl.style.top = `${scrollTop}px`;
  }

  function handleHoverOver(idx) {
    const relatedEls = document.querySelectorAll(`[data-rowindex="${idx}"]`);
    if (!isEmptyList(relatedEls)) {
      for (const element of relatedEls) {
        element.classList.add("hovered");
      }
    }
  }
  function handleHoverOut(idx) {
    const relatedEls = document.querySelectorAll(`[data-rowindex="${idx}"]`);
    if (!isEmptyList(relatedEls)) {
      for (const element of relatedEls) {
        element.classList.remove("hovered");
      }
    }
  }

  const columnStyle = {
    gridTemplateColumns: `repeat(${timeBuckets.length}, minmax(0, 1fr))`,
  };

  // Calculate total rows after taskInfoList is created
  const totalRows =
    (unassignedTaskInfoList?.length || 0) + (taskInfoList?.length || 0);
  const rowStyle = {
    gridTemplateRows: `repeat(${totalRows}, minmax(0, 1fr))`,
  };

  // Styles to position the markers
  const nowMarkerStyle = getTimelineMarkerStyle(mNow);

  // Update the department selection handler
  const handleDepartmentChange = (e) => {
    const departmentUuid = e.target.value;
    setSelectedDepartment(departmentUuid);
    // Reset scroll
    const scrollEl = containerRef?.current;
    if (!isNullOrUndefined(scrollEl)) {
      scrollEl.scrollTo(0, 0);
    }
  };

  // Show modal for editing assignments
  function handleShowAssignmentModal(taskInfo) {
    dispatch({
      type: "setIsRefreshPaused",
      value: true,
    });
    setTaskInfo(taskInfo);
  }

  // Show modal for crew details
  function handleShowCrewModal(taskInfo) {
    dispatch({
      type: "setIsRefreshPaused",
      value: true,
    });
    // taskInfo already includes the userInfo (taskInfo.userInfo)
    setCrewInfo(taskInfo);
  }

  return (
    <div className="tasks-overview">
      <div className="tasks-overview-header">
        <div className="titles">
          <h3>{t("live_roster")}</h3>
          <div className="subtitle">{t("view_and_manage_tasks")}</div>
        </div>
        <div className="actions">
          <div className="department-label">{t("department")}:</div>
          <div className="department-input">
            <select
              style={selectInputFix}
              value={selectedDepartment}
              onChange={handleDepartmentChange}
            >
              {departments?.map((department) => (
                <option key={department.uuid} value={department.uuid}>
                  {department.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="tasks-overview-body">
        <div
          className="tasks-overview-container"
          ref={containerRef}
          onScroll={handleContentScroll}
        >
          <div className="tasks-overview-header-row">
            <div className="cell-label">
              <div>Name</div>
            </div>
            <div style={columnStyle}>
              {timeBuckets &&
                timeBuckets.map((i, idx) => (
                  <div className="cell" key={`header-${idx}`}>
                    <div>{idx + 1 < timeBuckets.length && formatTime(i)}</div>
                  </div>
                ))}
            </div>
          </div>
          <div className="tasks-overview-content">
            <div className="tasks-overview-sidepanel" style={rowStyle}>
              {!isEmptyList(taskInfoList) &&
                taskInfoList.map((taskInfo, idx) => (
                  <div
                    className="cell-label clickable"
                    key={`sidepanel-${idx}`}
                    data-rowindex={idx}
                    onMouseOver={() => {
                      handleHoverOver(idx);
                    }}
                    onMouseOut={() => {
                      handleHoverOut(idx);
                    }}
                    onClick={() => {
                      handleShowCrewModal(taskInfo);
                    }}
                  >
                    <div>
                      <CrewInfo
                        user={taskInfo.userInfo.user}
                        mapStatus={taskInfo.userInfo.mapStatus}
                      />
                    </div>
                  </div>
                ))}
              {!isEmptyList(unassignedTaskInfoList) &&
                unassignedTaskInfoList.map((unassignedTask, idx) => (
                  <div
                    className="cell-label unassigned"
                    key={`sidepanel-unassigned-${idx}`}
                    data-rowindex={`unassigned-${idx}`}
                    onMouseOver={() => {
                      handleHoverOver(`unassigned-${idx}`);
                    }}
                    onMouseOut={() => {
                      handleHoverOut(`unassigned-${idx}`);
                    }}
                  >
                    <div>
                      <CrewInfo
                        user={{
                          firstName: t("unassigned"),
                          lasName: "",
                        }}
                      />
                    </div>
                  </div>
                ))}
            </div>
            <div className="tasks-overview-mainpanel">
              <div
                className="tasks-overview-gridlines"
                style={rowStyle}
                ref={gridlinesRef}
              >
                {taskInfoList &&
                  taskInfoList.map((taskInfo, idx) => (
                    <div
                      key={`grid-${idx}`}
                      style={columnStyle}
                      data-rowindex={idx}
                      onMouseOver={() => {
                        handleHoverOver(idx);
                      }}
                      onMouseOut={() => {
                        handleHoverOut(idx);
                      }}
                    >
                      {timeBuckets &&
                        timeBuckets.map((i, subIdx) => (
                          <div
                            className="gridcell"
                            key={`grid-${idx}-${subIdx}`}
                          >
                            <div data-time-bucket={formatTime(i)}></div>
                          </div>
                        ))}
                    </div>
                  ))}
                {!isEmptyList(unassignedTaskInfoList) &&
                  unassignedTaskInfoList.map((unassignedTask, idx) => (
                    <div
                      key={`grid-${idx}`}
                      style={columnStyle}
                      data-rowindex={`unassigned-${idx}`}
                      onMouseOver={() => {
                        handleHoverOver(`unassigned-${idx}`);
                      }}
                      onMouseOut={() => {
                        handleHoverOut(`unassigned-${idx}`);
                      }}
                    >
                      {timeBuckets &&
                        timeBuckets.map((i, subIdx) => (
                          <div
                            className="gridcell"
                            key={`grid-${idx}-${subIdx}`}
                          >
                            <div data-time-bucket={formatTime(i)}></div>
                          </div>
                        ))}
                    </div>
                  ))}
              </div>
              <div className="tasks-overview-timelinebars" style={rowStyle}>
                {taskInfoList &&
                  taskInfoList.map((taskInfo, idx) => (
                    <div key={`timelinebar-${idx}`}>
                      {/** Crew shift timeline bars **/}
                      {taskInfo?.userCrewShifts &&
                        taskInfo?.userCrewShifts.map((userTask, subIdx) => {
                          const timelineStyle = getTimelineBarStyle(userTask);
                          return (
                            <Tooltip
                              key={`timelinebar-crewshift-${idx}-${subIdx}`}
                              title={
                                <CrewShiftTooltip
                                  crewShift={userTask}
                                  timezone={airportTimezone}
                                />
                              }
                              placement="left"
                              componentsProps={tooltipStyles}
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 500 }}
                              arrow
                            >
                              <div
                                className="tasks-overview-timelinebar crewshift"
                                style={timelineStyle}
                              >
                                <div>
                                  <div className="tasks-overview-timelinebar-value"></div>
                                </div>
                              </div>
                            </Tooltip>
                          );
                        })}
                      {/** Task timeline bars **/}
                      {taskInfo?.userTasks &&
                        taskInfo?.userTasks.map((userTask, subIdx) => {
                          const timelineStyle = getTimelineBarStyle(userTask);
                          return (
                            <Tooltip
                              key={`timelinebar-task-${idx}-${subIdx}`}
                              title={
                                <TaskTooltip
                                  userTask={userTask}
                                  timezone={airportTimezone}
                                />
                              }
                              placement="top"
                              componentsProps={tooltipStyles}
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 500 }}
                              arrow
                            >
                              <div
                                className={`tasks-overview-timelinebar${
                                  userTask.duration === 0 ? " point" : ""
                                }`}
                                style={timelineStyle}
                                onClick={() => {
                                  handleShowAssignmentModal({
                                    userUuid: taskInfo.userUuid,
                                    userTask: userTask,
                                  });
                                }}
                              >
                                <div>
                                  <div className="tasks-overview-timelinebar-value">
                                    {userTask?.turnaroundInfo
                                      ?.combinedFlightName ||
                                      userTask?.turnaroundUuid}
                                    : {userTask?.operationType?.name}
                                  </div>
                                </div>
                              </div>
                            </Tooltip>
                          );
                        })}
                    </div>
                  ))}
                {!isEmptyList(unassignedTaskInfoList) &&
                  unassignedTaskInfoList.map((unassignedTask, idx) => {
                    const timelineStyle = getTimelineBarStyle(unassignedTask);
                    return (
                      <div key={`unassigned-${idx}`}>
                        <Tooltip
                          title={
                            <TaskTooltip
                              userTask={unassignedTask}
                              timezone={airportTimezone}
                            />
                          }
                          placement="top"
                          componentsProps={tooltipStyles}
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 500 }}
                          arrow
                        >
                          <div
                            className={`tasks-overview-timelinebar unassigned${
                              unassignedTask.duration === 0 ? " point" : ""
                            }`}
                            style={timelineStyle}
                            onClick={() => {
                              handleShowAssignmentModal({
                                unassignedTask: unassignedTask,
                              });
                            }}
                          >
                            <div>
                              <div className="tasks-overview-timelinebar-value">
                                {unassignedTask?.turnaroundInfo
                                  ?.combinedFlightName ||
                                  unassignedTask?.turnaroundUuid}
                                : {unassignedTask?.operationType?.name}
                              </div>
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div
            className="tasks-overview-timeline-markers"
            style={{ top: 0 }}
            ref={markersRef}
          >
            <div className="timeline-marker" style={nowMarkerStyle}>
              <div>
                <div className="timeline-marker-label-anchor">
                  <div className="timeline-marker-label">
                    <div>
                      <div className="timeline-marker-label-title">Now</div>
                      <div className="timeline-marker-label-value">
                        {formatTime(mNow, airportTimezone)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="timeline-marker-line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNullOrUndefined(taskInfo) && (
        <TaskModal
          taskInfo={taskInfo}
          onClose={() => {
            dispatch({
              type: "setIsRefreshPaused",
              value: false,
            });
            setTaskInfo(null);
          }}
        />
      )}
      {!isNullOrUndefined(crewInfo) && (
        <CrewTaskModal
          crewInfo={crewInfo}
          onClose={() => {
            dispatch({
              type: "setIsRefreshPaused",
              value: false,
            });
            setCrewInfo(null);
          }}
          timezone={airportTimezone}
        />
      )}
    </div>
  );
}

function CrewShiftTooltip(props) {
  const { crewShift, timezone } = props;
  return (
    <div>
      <div>Crew shift</div>
      <div className="tooltip-timestamps">
        {formatTimeRange(
          crewShift.expectedStartTime,
          crewShift.expectedEndTime,
          timezone
        )}
      </div>
    </div>
  );
}

function TaskTooltip(props) {
  const { userTask, timezone } = props;
  return (
    <div>
      <div>
        {userTask.turnaroundInfo.combinedFlightName}:{" "}
        {userTask.operationType.name}
      </div>
      <div className="tooltip-timestamps">
        {formatTimeRange(
          userTask.expectedStartTime,
          userTask.expectedEndTime,
          timezone
        )}
      </div>
    </div>
  );
}

export default LiveRoster;
