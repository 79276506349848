import { useTranslation } from "react-i18next";
import { Modal } from "@mui/material";
import { formatTime, isEmptyList, isNullOrUndefined } from "../../utils";
import { ReactComponent as Cross } from "../../assets/cross.svg";
import CrewInfo from "../CrewInfo";
import moment from "moment-timezone";

function CrewTaskModal(props) {
  const { t } = useTranslation();

  const { crewInfo, onClose, timezone } = props;

  function handleCloseModal() {
    onClose();
  }

  const mNow = moment().tz(timezone);

  return (
    <div>
      <Modal open={!isNullOrUndefined(crewInfo)} onClose={handleCloseModal}>
        <div className="modal">
          {!isNullOrUndefined(crewInfo) && (
            <>
              <div>
                <div className="modal-header">
                  <h2>{t("view_crew")}</h2>
                  <div className="button-icon" onClick={handleCloseModal}>
                    <Cross />
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="modal-container">
                  <div className="crew-cert-content">
                    <div className="crew-cert-profile">
                      <CrewInfo
                        user={crewInfo.userInfo.user}
                        details={t(crewInfo.userInfo.dispatchStatus)}
                        mapStatus={crewInfo.userInfo.mapStatus}
                      />
                    </div>
                    <div className="crew-cert-body">
                      <div className="crew-cert-header">
                        <h2>
                          {t("crew_shifts")} (
                          {!isEmptyList(crewInfo.userCrewShifts)
                            ? crewInfo.userCrewShifts.length
                            : 0}
                          )
                        </h2>
                      </div>
                      <div className="crew-cert-module">
                        <div className="crew-shift-list">
                          {!isEmptyList(crewInfo.userCrewShifts) &&
                            crewInfo.userCrewShifts.map((shift, idx) => (
                              <div className="crew-shift-row" key={idx}>
                                <div>
                                  {formatTime(
                                    shift.expectedStartTime,
                                    timezone,
                                    true,
                                    mNow.toDate()
                                  )}
                                  &nbsp;-&nbsp;
                                  {formatTime(
                                    shift.expectedEndTime,
                                    timezone,
                                    true,
                                    mNow.toDate()
                                  )}
                                </div>
                                <div>{shift.departmentName}</div>
                              </div>
                            ))}
                          {isEmptyList(crewInfo.userCrewShifts) && (
                            <div className="crew-shift-row">
                              <div className="empty-state">
                                {t("crew_shifts_empty_department")}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="crew-cert-header">
                        <h2>
                          {t("certifications")} (
                          {!isEmptyList(crewInfo.userInfo.certList)
                            ? crewInfo.userInfo.certList.length
                            : 0}
                          )
                        </h2>
                      </div>
                      <div className="crew-cert-module">
                        <div className="crew-cert-list">
                          {!isEmptyList(crewInfo.userInfo.certList) &&
                            crewInfo.userInfo.certList.map((certName, idx) => (
                              <div className="crew-cert-row" key={idx}>
                                <div>{certName}</div>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div></div>
                <div>
                  <button className="secondary" onClick={handleCloseModal}>
                    {t("close")}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default CrewTaskModal;
