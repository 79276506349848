import { useReducer } from "react";
import {
  TasksOverviewContext,
  TasksOverviewDispatchContext,
} from "./TasksOverviewContext";

export const initialState = {
  globalError: null,
  departmentRoster: [],
  departmentRosterLoading: false,
};

export function tasksOverviewReducer(state, action) {
  switch (action.type) {
    case "setGlobalError":
      return {
        ...state,
        globalError: action.globalError,
      };
    case "setDepartmentRoster":
      return {
        ...state,
        departmentRoster: action.value,
      };
    case "setDepartmentRosterLoading":
      return {
        ...state,
        departmentRosterLoading: action.value,
      };
    default:
      console.log(`Unknown action: ${action.type}`);
      return {
        ...state,
      };
  }
}
export default function TasksOverviewProvider({ children }) {
  const [main, dispatch] = useReducer(tasksOverviewReducer, initialState);
  return (
    <TasksOverviewContext.Provider value={main}>
      <TasksOverviewDispatchContext.Provider value={dispatch}>
        {children}
      </TasksOverviewDispatchContext.Provider>
    </TasksOverviewContext.Provider>
  );
}
