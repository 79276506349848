import { useReducer } from "react";
import {
  CrewShiftsContext,
  CrewShiftsDispatchContext,
} from "./CrewShiftsContext";

export const initialState = {
  globalError: null,
  globalErrorList: null,
  crewShifts: [],
  crewShiftsSaving: false,
  crewShiftsCreateSaving: false,
  crewShiftsUpdateSaving: false,
  crewShiftsDeleteSaving: false,
  fileUpload: null,
  fileUploadLoading: false,
  crewShiftsImport: null,
  crewShiftsImportSaving: false,
  crewShiftsImportPreview: null,
  crewShiftsImportPreviewSaving: false,
};

export function crewShiftsReducer(state, action) {
  switch (action.type) {
    case "setCrewShifts":
      return {
        ...state,
        crewShifts: action.value,
      };
    case "setCrewShiftsSaving":
      return {
        ...state,
        crewShiftsSaving: action.value,
      };
    case "setCrewShiftsDeleteSaving":
      return {
        ...state,
        crewShiftsDeleteSaving: action.value,
      };
    case "setCrewShiftsCreateSaving":
      return {
        ...state,
        crewShiftsCreateSaving: action.value,
      };
    case "setCrewShiftsUpdateSaving":
      return {
        ...state,
        crewShiftsUpdateSaving: action.value,
      };
    case "setFileUpload":
      return {
        ...state,
        fileUpload: action.value,
      };
    case "setFileUploadLoading":
      return {
        ...state,
        fileUploadLoading: action.value,
      };
    case "setCrewShiftsImport":
      return {
        ...state,
        crewShiftsImport: action.value,
      };

    case "setCrewShiftsImportSaving":
      return {
        ...state,
        crewShiftsImportSaving: action.value,
      };
    case "setCrewShiftsImportPreview":
      return {
        ...state,
        crewShiftsImportPreview: action.value,
      };

    case "setCrewShiftsImportPreviewSaving":
      return {
        ...state,
        crewShiftsImportPreviewSaving: action.value,
      };
    case "setGlobalError":
      return {
        ...state,
        globalError: action.globalError,
        globalErrorList: action.globalErrorList,
      };
    default:
      console.log(`Unknown action: ${action.type}`);
      return {
        ...state,
      };
  }
}

export default function CrewShiftsProvider({ children }) {
  const [main, dispatch] = useReducer(crewShiftsReducer, initialState);
  return (
    <CrewShiftsContext.Provider value={main}>
      <CrewShiftsDispatchContext.Provider value={dispatch}>
        {children}
      </CrewShiftsDispatchContext.Provider>
    </CrewShiftsContext.Provider>
  );
}
