import { useEffect, useState } from "react";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import {
  getNotifications,
  getPositions,
  getTurnaroundAllocations,
  getTurnaroundsSummary,
} from "../../api";
import { POLLING_INTERVALS } from "../../constants";
import { getCriteriaForPositions } from "../../mapUtils";
import {
  getTimezoneFromUser,
  isEmptyList,
  isNullOrUndefined,
} from "../../utils";
import moment from "moment-timezone";
import { useChatContext, useChatDispatchContext } from "../../ChatContext";
import { getChatSummary } from "../../chatApi";

/* Component that just polls/fetches data that can be used globally by components */
function ConsoleDataHelper() {
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const {
    currentUser,
    turnaroundsSummaryLoading,
    positionsSaving,
    turnaroundAllocationsLoading,
    isRefreshPaused,
    vehicles,
    flightsToRender,
    showMapInfo,
    showMapRoutes,
    notificationsLoading,
  } = mainContext;
  const chatDispatch = useChatDispatchContext();
  const chatContext = useChatContext();
  const { chatSummary, chatSummaryLoading, chatChannelMessageSaving } =
    chatContext;
  // const location = useLocation();
  const [refreshNotificationsRequested, setRefreshNotificationsRequested] =
    useState(false);

  const [refreshPositionsRequested, setRefreshPositionsRequested] =
    useState(false);
  const [chatSummaryRequested, setChatSummaryRequested] = useState(false);
  const [
    refreshTurnaroundsSummaryRequested,
    setRefreshTurnaroundsSummaryRequested,
  ] = useState(false);
  const [
    refreshTurnaroundAllocationsRequested,
    setRefreshTurnaroundAllocationsRequested,
  ] = useState(false);

  useEffect(() => {
    let unreadChatChannels = 0;
    if (!isEmptyList(chatSummary?.channels)) {
      chatSummary.channels.forEach((item) => {
        if (item.hasUnreadMessages) {
          unreadChatChannels++;
        }
      });
    }
    dispatch({
      type: "setUnreadChatChannels",
      value: unreadChatChannels,
    });
  }, [dispatch, chatSummary]);

  // Refresh comms
  useEffect(() => {
    if (isRefreshPaused) return () => {};
    if (
      !chatSummaryLoading &&
      !chatChannelMessageSaving &&
      chatSummaryRequested
    ) {
      getChatSummary(chatDispatch);
      setChatSummaryRequested(false);
    }
  }, [
    chatDispatch,
    chatSummaryRequested,
    chatSummaryLoading,
    chatChannelMessageSaving,
    isRefreshPaused,
  ]);

  // Refresh positions for dispatch
  useEffect(() => {
    if (isRefreshPaused) return () => {};
    if (!refreshPositionsRequested) return () => {};
    if (positionsSaving) return () => {};
    const criteria = getCriteriaForPositions(
      vehicles,
      flightsToRender,
      showMapInfo,
      showMapRoutes
    );
    getPositions(dispatch, criteria);
    setRefreshPositionsRequested(false);
  }, [
    dispatch,
    positionsSaving,
    refreshPositionsRequested,
    isRefreshPaused,
    vehicles,
    flightsToRender,
    showMapInfo,
    showMapRoutes,
  ]);

  // Refresh TurnarnoundsSummary (previously Flights to Render)
  useEffect(() => {
    if (isRefreshPaused) return () => {};
    if (turnaroundsSummaryLoading) return () => {};
    if (!refreshTurnaroundsSummaryRequested) return () => {};
    getTurnaroundsSummary(dispatch);
    setRefreshTurnaroundsSummaryRequested(false);
  }, [
    dispatch,
    turnaroundsSummaryLoading,
    refreshTurnaroundsSummaryRequested,
    isRefreshPaused,
  ]);

  // Refresh turnaround allocations
  useEffect(() => {
    if (isRefreshPaused) return () => {};
    if (!refreshTurnaroundAllocationsRequested) return () => {};
    if (!turnaroundAllocationsLoading) {
      getTurnaroundAllocations(dispatch);
      setRefreshTurnaroundAllocationsRequested(false);
    }
  }, [
    dispatch,
    turnaroundAllocationsLoading,
    refreshTurnaroundAllocationsRequested,
    isRefreshPaused,
  ]);

  // Refresh notifications
  useEffect(() => {
    if (isRefreshPaused) return () => {};
    if (!refreshNotificationsRequested) return () => {};
    if (isNullOrUndefined(currentUser)) return () => {};
    if (!notificationsLoading) {
      const airportTimezone = getTimezoneFromUser(currentUser);
      const mNow = moment().tz(airportTimezone);
      const endTime = mNow.toDate().toISOString();
      // Load notifications on page load
      getNotifications(dispatch, endTime);
      setRefreshNotificationsRequested(false);
    }
  }, [
    dispatch,
    notificationsLoading,
    refreshNotificationsRequested,
    isRefreshPaused,
    currentUser,
  ]);

  // Setup intervals here (only once)
  useEffect(() => {
    const intervals = [];

    if (POLLING_INTERVALS.MAP_POSITIONS > 0) {
      const interval = setInterval(() => {
        setRefreshPositionsRequested(true);
      }, POLLING_INTERVALS.MAP_POSITIONS);
      intervals.push(interval);
    }

    if (POLLING_INTERVALS.CHAT_SUMMARY > 0) {
      const interval = setInterval(() => {
        setChatSummaryRequested(true);
      }, POLLING_INTERVALS.CHAT_SUMMARY);
      intervals.push(interval);
    }

    if (POLLING_INTERVALS.TURNAROUNDS_SUMMARY > 0) {
      const interval = setInterval(() => {
        setRefreshTurnaroundsSummaryRequested(true);
      }, POLLING_INTERVALS.TURNAROUNDS_SUMMARY);
      intervals.push(interval);
    }

    if (POLLING_INTERVALS.ALLOCATIONS > 0) {
      const interval = setInterval(() => {
        setRefreshTurnaroundAllocationsRequested(true);
      }, POLLING_INTERVALS.ALLOCATIONS);
      intervals.push(interval);
    }

    if (POLLING_INTERVALS.NOTIFICATIONS > 0) {
      const interval = setInterval(() => {
        setRefreshNotificationsRequested(true);
      }, POLLING_INTERVALS.NOTIFICATIONS);
      intervals.push(interval);
    }
    return () => {
      for (let i = 0; i < intervals.length; i++) {
        clearInterval(intervals[i]);
      }
    };
  }, []);

  return <></>;
}
export default ConsoleDataHelper;
