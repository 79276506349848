import { ReactComponent as Turnaround } from "../../assets/turnarounds/turnaround.svg";
import { ReactComponent as CloseBtn } from "../../assets/close-btn.svg";
import { ReactComponent as CircleInbound } from "../../assets/turnarounds/inbound_circle.svg";
import { ReactComponent as CircleOutbound } from "../../assets/turnarounds/outbound_circle.svg";
import {
  filterList,
  formatDatetime,
  formatTime,
  isBlank,
  isEmptyList,
  isNullOrUndefined,
  sortByField,
} from "../../utils";
import { useState } from "react";
import TabControl from "../TabControl";
import {
  TurnaroundOperationStatus,
  TurnaroundPhaseType,
  getCurrentTurnaroundPhase,
} from "../../turnaroundUtils";
import TurnaroundProgressBar from "./TurnaroundProgressBar";
import { DEFAULT_TIMEZONE, EMPTY_STAND } from "../../constants";
import {
  ResourceActions,
  userHasResourceAction,
} from "../../resourceActionsUtils";
import { useMainContext } from "../../MainContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getFlightInfo,
  getFlightInfoForTurnaroundSummary,
} from "../../flightUtils";
import FlightProgress from "../FlightProgress";
import StatusBadge from "../StatusBadge";
import StatusBadges from "../StatusBadge/StatusBadges";
import ScheduledTimeValue from "./ScheduledTimeValue";

function TurnaroundInfoPanel(props) {
  const {
    onClose,
    panelOpen,
    selectedTurnaroundInfo,
    selectedOperation,
    detailsClickHandler,
    selectedDate,
    showGateChangeAction,
    onGateChangeClick,
    showAlertsPanelAction,
    onShowAlertsClick,
    onUnassignedUserClick,
    onShowTurnaroundChatClick,
    showTurnaroundChat,
    showUnassignedUserRecords,
  } = props;
  const { t } = useTranslation();
  const mainContext = useMainContext();
  const navigate = useNavigate();
  const { currentUser } = mainContext;
  const defaultTab = getCurrentTurnaroundPhase(selectedTurnaroundInfo);
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const turnaroundAirline = !isNullOrUndefined(selectedTurnaroundInfo)
    ? selectedTurnaroundInfo.inboundFlightAirlineUuid ||
      selectedTurnaroundInfo.outboundFlightAirlineUuid
    : null;
  const airport = currentUser?.airport;

  const showDispatchActions =
    selectedTurnaroundInfo?.isDispatched &&
    userHasResourceAction(
      currentUser,
      ResourceActions.EditTurnProfile,
      turnaroundAirline
    );

  const showTurnaroundChatButton =
    showTurnaroundChat &&
    !isNullOrUndefined(onShowTurnaroundChatClick) &&
    !isNullOrUndefined(selectedTurnaroundInfo?.chatChannelId);

  function handleEditDispatch() {
    // TODO: Analytics event?
    navigate(`/turnarounds/${selectedTurnaroundInfo.uuid}`);
  }

  return (
    <>
      <div className="context-panel-content-header">
        <div></div>
        <div className="close-btn" onClick={onClose}>
          <CloseBtn />
        </div>
      </div>
      <div className="turnaround-navigation">
        <TabControl
          clickHandler={setSelectedTab}
          tabNames={[t("inbound"), t("turnaround"), t("outbound")]}
          selectedTabIdx={selectedTab}
        />
      </div>
      <div className="context-panel-container">
        <div className={`turnaround-content ${panelOpen ? " open" : ""}`}>
          {selectedTab === TurnaroundPhaseType.INBOUND &&
            getContentForInbound(
              t,
              selectedTurnaroundInfo,
              selectedDate,
              airport
            )}
          {selectedTab === TurnaroundPhaseType.TURNAROUND &&
            getContentForTurnaround(
              t,
              airport,
              selectedTurnaroundInfo,
              selectedOperation,
              detailsClickHandler,
              selectedDate,
              showGateChangeAction,
              onGateChangeClick,
              showAlertsPanelAction,
              onShowAlertsClick,
              showDispatchActions,
              handleEditDispatch,
              onUnassignedUserClick,
              showTurnaroundChatButton,
              onShowTurnaroundChatClick,
              showUnassignedUserRecords
            )}
          {selectedTab === TurnaroundPhaseType.OUTBOUND &&
            getContentForOutbound(
              t,
              selectedTurnaroundInfo,
              selectedDate,
              airport
            )}
        </div>
      </div>
    </>
  );
}

/** Functions for obtaining content per tab **/
function getContentForTurnaround(
  t,
  airport,
  selectedTurnaroundInfo,
  selectedOperation,
  detailsClickHandler,
  selectedDate,
  showGateChangeAction = false,
  onGateChangeClick,
  showAlertsPanelAction = false,
  onShowAlertsClick,
  showDispatchActions,
  handleEditDispatch,
  onUnassignedUserClick,
  showTurnaroundChatButton,
  onShowTurnaroundChatClick,
  showUnassignedUserRecords = false
) {
  const operationsList = filterList(
    selectedTurnaroundInfo.operations,
    (item) => {
      return item.enabled;
    }
  );
  sortByField(operationsList, "expectedStartTime", ["name"]);

  const airportTimezone = airport.timezone || DEFAULT_TIMEZONE;
  const hasAction =
    showGateChangeAction || showAlertsPanelAction || showTurnaroundChatButton;

  // Determine the number of expected vs assigned Crew from the original profile
  // As well as expected vs actual Crew based on service records
  const expectedQuantityByOperationId = {};
  if (!isNullOrUndefined(selectedTurnaroundInfo?.originalProfile)) {
    const operations =
      selectedTurnaroundInfo.originalProfile.turnaroundOperations;
    for (let i = 0; i < operations.length; i++) {
      const operation = operations[i];
      if (operation.enabled) {
        const requirements = operation.turnaroundRequirements;
        let totalCrewQuantity = 0;
        let totalCrewAssignments = 0;

        for (let j = 0; j < requirements.length; j++) {
          const requirement = requirements[j];
          totalCrewQuantity = totalCrewQuantity + requirement.quantity;
          const crewAssignments = !isNullOrUndefined(
            requirement.crewAssignments
          )
            ? requirement.crewAssignments
            : [];
          totalCrewAssignments = totalCrewAssignments + crewAssignments.length;
        }
        expectedQuantityByOperationId[operation.uuid] = {
          operationName: operation?.type.name,
          totalCrewQuantity: totalCrewQuantity,
          totalCrewAssignments: totalCrewAssignments,
          missingCrewAssignments:
            totalCrewAssignments < totalCrewQuantity
              ? totalCrewQuantity - totalCrewAssignments
              : 0,
        };
      }
    }
  }
  // Determine the number of expected vs actual GSE from the monitor (has service records)
  if (!isNullOrUndefined(selectedTurnaroundInfo?.originalMonitor)) {
    const operations =
      selectedTurnaroundInfo.originalMonitor.turnaroundOperations;
    for (let i = 0; i < operations.length; i++) {
      const operation = operations[i];
      if (operation.enabled) {
        const requirements = !isNullOrUndefined(
          operation.turnaroundRequirements
        )
          ? operation.turnaroundRequirements
          : [];
        const gseRecords = !isNullOrUndefined(operation.gseRecords)
          ? operation.gseRecords
          : [];

        let totalRequirementQuantity = 0;
        let totalGseQuantity = 0;
        let isCrewOnlyRequirement = true; // Crew only requirements do not have GSEs
        for (let j = 0; j < requirements.length; j++) {
          const requirement = requirements[j];
          if (
            !isNullOrUndefined(
              requirement?.certification?.groundVehicleTypeUuid
            )
          ) {
            totalGseQuantity = totalGseQuantity + requirement.quantity;
            isCrewOnlyRequirement = false;
          }
          totalRequirementQuantity =
            totalRequirementQuantity + requirement.quantity;
        }
        let expectedQuantityForOperation =
          expectedQuantityByOperationId[operation.uuid];
        if (isNullOrUndefined(expectedQuantityForOperation)) {
          expectedQuantityByOperationId[operation.uuid] = {};
          expectedQuantityForOperation =
            expectedQuantityByOperationId[operation.uuid];
        }
        const actualGseQuantity = gseRecords.length;
        expectedQuantityForOperation.totalGseQuantity = totalGseQuantity;
        expectedQuantityForOperation.actualGseQuantity = actualGseQuantity;
        expectedQuantityForOperation.missingGses =
          actualGseQuantity < totalGseQuantity
            ? totalGseQuantity - actualGseQuantity
            : 0;

        const totalUserRecords = !isNullOrUndefined(operation.userRecords)
          ? operation.userRecords.length
          : 0;
        const missingCrew =
          isCrewOnlyRequirement && totalUserRecords < totalRequirementQuantity
            ? totalRequirementQuantity - totalUserRecords
            : 0;
        expectedQuantityForOperation.missingCrew = missingCrew;
      }
    }
  }

  const unassignedUserRecords =
    selectedTurnaroundInfo?.originalMonitor?.unassignedUserRecords;

  const labelsToRender = selectedTurnaroundInfo?.activeLabels;
  const hasLabelsToRender = !isEmptyList(labelsToRender);
  if (hasLabelsToRender) {
    sortByField(labelsToRender, "name");
  }

  return (
    <>
      <div className="turnaround-general">
        <div className="turn-thirds">
          <div className="stat">
            <label>{t("inbound")}</label>
            <span>{selectedTurnaroundInfo.inboundFlightName || "-"}</span>
          </div>
          <div>
            <Turnaround />
          </div>
          <div className="stat">
            <label>{t("outbound")}</label>
            <span>{selectedTurnaroundInfo.outboundFlightName || "-"}</span>
          </div>
        </div>

        <div className="stat double">
          <label>{t("current_stand")}</label>
          <span>
            {!isNullOrUndefined(selectedTurnaroundInfo.snappedStand)
              ? selectedTurnaroundInfo.snappedStand.name
              : EMPTY_STAND}
          </span>
        </div>
        {hasLabelsToRender && (
          <div className="stat double">
            <label>{t("special_cases")}</label>
            <span>
              <StatusBadges>
                {labelsToRender &&
                  labelsToRender.map((label, idx) => (
                    <StatusBadge
                      key={idx}
                      message={label.name}
                      isGeneric={true}
                      isAllCap={true}
                    />
                  ))}
              </StatusBadges>
            </span>
          </div>
        )}
        <div className="stat ">
          <label>{t("gate_in_non_cap")}</label>
          <ScheduledTimeValue
            actualTime={selectedTurnaroundInfo?.gateIn}
            scheduledTime={selectedTurnaroundInfo?.scheduledGateInTime}
            timezone={airportTimezone}
            selectedDate={selectedDate}
          />
        </div>
        <div className="stat">
          <label>{t("gate_out_non_cap")}</label>
          <ScheduledTimeValue
            actualTime={selectedTurnaroundInfo?.gateOut}
            scheduledTime={selectedTurnaroundInfo?.scheduledGateOutTime}
            timezone={airportTimezone}
            selectedDate={selectedDate}
          />
        </div>
      </div>

      <div className="turnaround-general">
        <div className="turn-locations">
          <div className="stat">
            <label>{t("assigned_arr_stand_abbr")}</label>
            <span>
              {!isBlank(selectedTurnaroundInfo?.arrivalStand?.name)
                ? selectedTurnaroundInfo.arrivalStand.name
                : EMPTY_STAND}
            </span>
          </div>
          <div className="stat">
            <label>{t("assigned_hard_stand")}</label>
            <span>
              {!isBlank(selectedTurnaroundInfo?.hardStand?.name)
                ? selectedTurnaroundInfo.hardStand.name
                : EMPTY_STAND}
            </span>
          </div>
          <div className="stat">
            <label>{t("assigned_dep_stand_abbr")}</label>
            <span>
              {!isBlank(selectedTurnaroundInfo?.departureStand?.name)
                ? selectedTurnaroundInfo.departureStand.name
                : EMPTY_STAND}
            </span>
          </div>
        </div>

        <div className="stat ">
          <label>{t("registration")}</label>
          <span>{selectedTurnaroundInfo.registration}</span>
        </div>
        <div className="stat">
          <label>{t("aircraft_type_non_cap")}</label>
          <span>{selectedTurnaroundInfo.aircraftType}</span>
        </div>
      </div>
      {showTurnaroundChatButton && (
        <div className="turnaround-action">
          <button className="alternate" onClick={onShowTurnaroundChatClick}>
            {t("view_turnaround_chat")}
          </button>
        </div>
      )}
      <TurnaroundProgressBar
        turnaroundInfo={selectedTurnaroundInfo}
        turnaroundLevelOperationStatus={true}
      />

      {operationsList.length > 0 && (
        <div className="turnaround-operations">
          {operationsList.length > 0 &&
            operationsList.map((item) => {
              let crewCount = 0;
              if (!isNullOrUndefined(item.turnaroundRequirements)) {
                for (let i = 0; i < item.turnaroundRequirements.length; i++) {
                  const turnRequirement = item.turnaroundRequirements[i];
                  if (turnRequirement.crewAssignments?.length > 0) {
                    crewCount =
                      crewCount + turnRequirement.crewAssignments.length;
                  }
                }
              }

              const startTimeDisplay = !isNullOrUndefined(
                item.expectedStartTime
              )
                ? formatTime(item.expectedStartTime, airportTimezone)
                : null;
              const endTimeDisplay = !isNullOrUndefined(item.expectedEndTime)
                ? formatTime(item.expectedEndTime, airportTimezone)
                : null;

              const displayTime =
                !isNullOrUndefined(startTimeDisplay) &&
                !isNullOrUndefined(endTimeDisplay)
                  ? `${startTimeDisplay} - ${endTimeDisplay}`
                  : !isNullOrUndefined(startTimeDisplay)
                  ? startTimeDisplay
                  : !isNullOrUndefined(endTimeDisplay)
                  ? endTimeDisplay
                  : null;

              const infoText = {
                uuid: item.uuid,
                status: item.status,
                expectedStartTime: item.expectedStartTime
                  ? formatDatetime(item.expectedStartTime, airportTimezone)
                  : t("na").toUpperCase(),
              };
              const isInProgress =
                item?.status.statusId ===
                  TurnaroundOperationStatus.IN_PROGRESS ||
                item?.status.statusId ===
                  TurnaroundOperationStatus.IN_PROGRESS_LATE;

              return (
                <div
                  className={`stat clickable${
                    selectedOperation?.uuid === item.uuid ? " selected" : ""
                  }`}
                  onClick={() => {
                    if (selectedOperation?.uuid === item.uuid) {
                      detailsClickHandler(null);
                    } else {
                      detailsClickHandler(item);
                    }
                  }}
                  key={item.uuid}
                >
                  <div
                    className="stat-content"
                    data-info={JSON.stringify(infoText)}
                  >
                    <div className="status-info">
                      <label>
                        {item.name}
                        {isInProgress && (
                          <>
                            <div className="status-info-pulse fadeInLoop"></div>
                          </>
                        )}
                      </label>
                      <span className="all-caps">
                        {t("expected")}: {displayTime}
                      </span>
                      <span className="allcap">
                        {t("assigned_crew_web", {
                          value: crewCount === 0 ? "-" : crewCount,
                        })}
                      </span>
                    </div>
                    <div className="status">
                      {(item?.status.statusId ===
                        TurnaroundOperationStatus.MISSING ||
                        item?.status.statusId ===
                          TurnaroundOperationStatus.IN_PROGRESS_LATE) && (
                        <div>
                          <StatusBadge
                            isCritical={true}
                            message={
                              item?.status.statusId ===
                              TurnaroundOperationStatus.MISSING
                                ? t("missing")
                                : t("delayed")
                            }
                          />
                        </div>
                      )}
                      {item?.status.statusId ===
                        TurnaroundOperationStatus.COMPLETED && (
                        <div>
                          <StatusBadge
                            isNominal={true}
                            message={t("completed_non_cap")}
                          />
                        </div>
                      )}
                      {item?.status.statusId ===
                        TurnaroundOperationStatus.COMPLETED_LATE && (
                        <div>
                          <StatusBadge
                            isNominal={true}
                            message={t("completed_late")}
                          />
                        </div>
                      )}
                      {item?.status.statusId ===
                        TurnaroundOperationStatus.COMPLETED_PARTIALLY && (
                        <div>
                          <StatusBadge
                            isNominal={true}
                            message={t("completed_partially")}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          {showUnassignedUserRecords &&
            !isNullOrUndefined(onUnassignedUserClick) &&
            unassignedUserRecords?.length > 0 && (
              <div
                className={`stat clickable unassigned`}
                onClick={() => {
                  onUnassignedUserClick(unassignedUserRecords[0]);
                }}
                key={"unassigned_user_records"}
              >
                <div className="stat-content">
                  <div className="status-info">
                    <label>{t("unassigned_crew")}</label>
                  </div>
                  <div className="status">
                    <div>
                      <StatusBadge
                        isWarning={true}
                        message={t("unresolved_web", {
                          value: unassignedUserRecords.length,
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      )}

      {hasAction && (
        <>
          <div className="turnaround-action">
            <button
              className="alternate"
              onClick={() => {
                handleEditDispatch();
              }}
            >
              {t("configure_turnaround")}
            </button>
            {showAlertsPanelAction && (
              <button className="alternate" onClick={onShowAlertsClick}>
                {t("view_timeline")}
              </button>
            )}
            {showGateChangeAction && (
              <button className="alternate" onClick={onGateChangeClick}>
                {t("move_aircraft")}
              </button>
            )}
          </div>
        </>
      )}
    </>
  );
}

function getContentForInbound(
  t,
  selectedTurnaroundInfo,
  selectedDate,
  airport
) {
  const landing = formatTime(
    selectedTurnaroundInfo.landing,
    airport.timezone,
    true,
    selectedDate
  );

  const sourceType = selectedTurnaroundInfo._sourceType;
  const inboundFlightInfo =
    sourceType === "summary"
      ? getFlightInfoForTurnaroundSummary(
          selectedTurnaroundInfo.inboundFlight,
          airport.iata,
          null,
          airport.timezone
        )
      : getFlightInfo(
          selectedTurnaroundInfo.inboundFlight,
          airport.iata,
          null,
          airport.timezone
        );
  return (
    <>
      <div className="turnaround-flight-header">
        <div>
          <div>
            <CircleInbound />
          </div>
          <div className="title">
            <label>{t("inbound_flight")}</label>
            <span>{selectedTurnaroundInfo.inboundFlightName}</span>
          </div>
        </div>
      </div>
      <FlightProgress flightInfo={inboundFlightInfo} />
      <div className="turnaround-general">
        <div className="stat">
          <label>{t("registration")}</label>
          <span>{selectedTurnaroundInfo.registration}</span>
        </div>
        <div className="stat">
          <label>{t("aircraft_type_non_cap")}</label>
          <span>{selectedTurnaroundInfo.inboundAircraftType}</span>
        </div>
        <div className="stat double">
          <label>{t("assigned_arr_stand")}</label>
          <span>
            {selectedTurnaroundInfo?.arrivalStand?.name || EMPTY_STAND}
          </span>
        </div>

        <div className="stat">
          <label>{t("gate_in_non_cap")}</label>
          <ScheduledTimeValue
            actualTime={selectedTurnaroundInfo?.gateIn}
            scheduledTime={selectedTurnaroundInfo?.scheduledGateInTime}
            timezone={selectedTurnaroundInfo.airportTimezone}
            selectedDate={selectedDate}
          />
        </div>
        <div className="stat">
          <label>{t("landing")}</label>
          <span title={selectedTurnaroundInfo.airportTimezone}>{landing}</span>
        </div>
      </div>
    </>
  );
}

function getContentForOutbound(
  t,
  selectedTurnaroundInfo,
  selectedDate,
  airport
) {
  const takeoff = formatTime(
    selectedTurnaroundInfo.takeoff,
    airport.timezone,
    true,
    selectedDate
  );

  const sourceType = selectedTurnaroundInfo._sourceType;
  const outboundFlightInfo =
    sourceType === "summary"
      ? getFlightInfoForTurnaroundSummary(
          selectedTurnaroundInfo.outboundFlight,
          airport.iata,
          null,
          airport.timezone
        )
      : getFlightInfo(
          selectedTurnaroundInfo.outboundFlight,
          airport.iata,
          null,
          airport.timezone
        );

  return (
    <>
      <div className="turnaround-flight-header">
        <div>
          <div>
            <CircleOutbound />
          </div>
          <div className="title">
            <label>{t("outbound_flight")}</label>
            <span>{selectedTurnaroundInfo.outboundFlightName || "-"}</span>
          </div>
        </div>
      </div>
      {outboundFlightInfo && <FlightProgress flightInfo={outboundFlightInfo} />}
      <div className="turnaround-general">
        <div className="stat">
          <label>{t("registration")}</label>
          <span>{selectedTurnaroundInfo.registration}</span>
        </div>
        <div className="stat">
          <label>{t("aircraft_type_non_cap")}</label>
          <span>{selectedTurnaroundInfo.inboundAircraftType}</span>
        </div>
        <div className="stat double">
          <label>{t("assigned_dep_stand")}</label>
          <span>
            {selectedTurnaroundInfo?.departureStand?.name || EMPTY_STAND}
          </span>
        </div>
        <div className="stat">
          <label>{t("gate_out_non_cap")}</label>
          <ScheduledTimeValue
            actualTime={selectedTurnaroundInfo?.gateOut}
            scheduledTime={selectedTurnaroundInfo?.scheduledGateOutTime}
            timezone={selectedTurnaroundInfo.airportTimezone}
            selectedDate={selectedDate}
          />
        </div>
        <div className="stat">
          <label>{t("takeoff")}</label>
          <span title={selectedTurnaroundInfo.airportTimezone}>{takeoff}</span>
        </div>
      </div>
    </>
  );
}

export default TurnaroundInfoPanel;
