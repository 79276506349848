import "./styles.css";
import { ReactComponent as LeftArrow } from "../../assets/arrow-left-sm.svg";
import { useTranslation } from "react-i18next";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import TitleBadge from "../TitleBadge";
import {
  formatLongDate,
  getTimezoneFromUser,
  isBlank,
  isEmptyList,
  isNullOrUndefined,
} from "../../utils";
import moment from "moment-timezone";
import { useEffect, useMemo, useState } from "react";
import ButtonDatePicker from "../ButtonDatePicker";
import { MIN_DATE, SEARCH_QUERY_PLACEHOLDERS } from "../../constants";
import DataTable from "../DataTable";
import {
  getTaskInfoForTurnaround,
  getTurnaroundInfo,
  getTurnaroundInfoForTurnaroundSummary,
} from "../../turnaroundUtils";
import {
  getGseTypes,
  getTurnaroundDetails,
  getTurnaroundsSummarySearch,
  getUsers,
  getVehicles,
} from "../../api";
import { TurnaroundsModuleColumns } from "../DataTable/TurnaroundsModuleColumns";
import { useNavigate, useParams } from "react-router-dom";
import TurnaroundsModuleTasks from "./TurnaroundsModuleTasks";
import TurnaroundsModuleConfig from "./TurnaroundsModuleConfig";
import { MAPINFO_MARKER_TYPE } from "../../mapUtils";

function TurnaroundsModule(props) {
  const { subPath } = props;
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const navigate = useNavigate();
  const { turnaroundUuid } = useParams();

  const {
    currentUser,
    turnaroundsSummarySearch,
    turnaroundDetails,
    searchQuery,
    vehicles,
    gseTypes,
  } = mainContext;
  const turnaroundsCount = turnaroundsSummarySearch?.length;
  const airportTimezone = getTimezoneFromUser(currentUser);
  const mToday = moment().tz(airportTimezone).startOf("day");

  const [selectedDate, setSelectedDate] = useState(mToday);
  const [selectedTurnaroundInfo, setSelectedTurnaroundInfo] = useState(null);
  const [taskInfoList, setTaskInfoList] = useState(null);

  useEffect(() => {
    // Load supporting data
    getGseTypes(dispatch);
    getVehicles(dispatch);
    getUsers(dispatch);
  }, [dispatch]);

  useEffect(() => {
    // Load required data
    const start = moment(selectedDate).toDate().toISOString();
    const end = moment(selectedDate).endOf("day").toDate().toISOString();
    getTurnaroundsSummarySearch(dispatch, start, end);
    getVehicles(dispatch);
  }, [dispatch, selectedDate]);

  useEffect(() => {
    // Load turnaround details
    if (!isNullOrUndefined(turnaroundUuid)) {
      getTurnaroundDetails(dispatch, { uuid: turnaroundUuid });
    } else {
      dispatch({
        type: "setTurnaroundDetails",
        value: null,
      });
    }
  }, [dispatch, turnaroundUuid]);

  useEffect(() => {
    dispatch({
      type: "setSearchQuery",
      value: {
        searchTerm: "",
        searchPlaceholder: SEARCH_QUERY_PLACEHOLDERS.SEARCH_TURNAROUNDS,
      },
    });
  }, [dispatch]);

  const dataList = useMemo(() => {
    const resultsToList = [];
    if (!isEmptyList(turnaroundsSummarySearch)) {
      for (let i = 0; i < turnaroundsSummarySearch.length; i++) {
        const turnaround = turnaroundsSummarySearch[i];
        const turnaroundInfo =
          getTurnaroundInfoForTurnaroundSummary(turnaround);

        resultsToList.push({
          ...turnaroundInfo,
          timezone: airportTimezone,
        });
      }
    }
    return resultsToList;
  }, [turnaroundsSummarySearch, airportTimezone]);

  useEffect(() => {
    if (isNullOrUndefined(turnaroundUuid)) {
      setSelectedTurnaroundInfo(null);
    } else {
      if (isNullOrUndefined(turnaroundDetails) || isNullOrUndefined(vehicles))
        return () => {};
      const turnaroundInfoToRender = !isNullOrUndefined(turnaroundDetails)
        ? getTurnaroundInfo(turnaroundDetails)
        : null;
      const taskInfoForTurnaround = !isNullOrUndefined(turnaroundInfoToRender)
        ? getTaskInfoForTurnaround(turnaroundInfoToRender, vehicles)
        : null;

      setSelectedTurnaroundInfo(turnaroundInfoToRender);
      setTaskInfoList(taskInfoForTurnaround);
    }
  }, [turnaroundUuid, turnaroundDetails, vehicles]);

  const showDetails =
    isBlank(subPath) && !isNullOrUndefined(selectedTurnaroundInfo);
  const showTimeline =
    subPath === "timeline" && !isNullOrUndefined(selectedTurnaroundInfo);

  return (
    <div className="turnarounds-module">
      <div
        className={`turnarounds-module-header${
          showDetails || showTimeline ? " sub-path" : ""
        }`}
      >
        <div>
          {(showDetails || showTimeline) && (
            <div>
              <button
                className="secondary"
                onClick={() => {
                  navigate("/turnarounds");
                }}
              >
                <LeftArrow />
                {t("back")}
              </button>
            </div>
          )}
          <div className="titles">
            {isNullOrUndefined(selectedTurnaroundInfo) && (
              <>
                <h3 className="badged">
                  {t("turnarounds")} <TitleBadge value={turnaroundsCount} />
                </h3>
                <div>
                  <span className="capitalized">
                    {formatLongDate(selectedDate)}
                    {selectedDate.isSame(mToday) && (
                      <>&nbsp;&middot;&nbsp;{t("today")}</>
                    )}
                  </span>
                </div>
              </>
            )}
            {(showDetails || showTimeline) && (
              <>
                <h3 className="badged">
                  {t("turnaround")}: {selectedTurnaroundInfo.combinedFlightName}
                  <TitleBadge
                    value={selectedTurnaroundInfo.statusDisplayName}
                  />
                </h3>
                <div>
                  <span className="capitalized">
                    {formatLongDate(selectedTurnaroundInfo.landing)}
                    {selectedDate.isSame(mToday) && (
                      <>&nbsp;&middot;&nbsp;{t("today")}</>
                    )}
                  </span>
                </div>
              </>
            )}
          </div>
          <div className="actions">
            {showDetails && !isNullOrUndefined(selectedTurnaroundInfo) && (
              <div>
                {selectedTurnaroundInfo.isCompleted && (
                  <button
                    className="secondary"
                    onClick={() => {
                      navigate(
                        `/turnarounds/${selectedTurnaroundInfo.uuid}/timeline`
                      );
                    }}
                  >
                    {t("view_timeline")}
                  </button>
                )}
                {!selectedTurnaroundInfo.isCompleted && (
                  <button
                    className="secondary"
                    onClick={() => {
                      setTimeout(() => {
                        dispatch({
                          type: "setShowOnMap",
                          value: {
                            markerType: MAPINFO_MARKER_TYPE.AIRCRAFT,
                            item: selectedTurnaroundInfo,
                          },
                        });
                      }, 250);
                      navigate("/map");
                    }}
                  >
                    {t("show_in_map")}
                  </button>
                )}
              </div>
            )}
            {showTimeline && !isNullOrUndefined(selectedTurnaroundInfo) && (
              <div>
                {selectedTurnaroundInfo.isCompleted && (
                  <button
                    className="secondary"
                    onClick={() => {
                      navigate(`/turnarounds/${selectedTurnaroundInfo.uuid}`);
                    }}
                  >
                    {t("view_details")}
                  </button>
                )}
              </div>
            )}

            {isNullOrUndefined(selectedTurnaroundInfo) && (
              <div>
                <ButtonDatePicker
                  label={
                    selectedDate == null
                      ? null
                      : selectedDate.format("MM/DD/YYYY")
                  }
                  value={selectedDate}
                  onChange={(newValue) => {
                    setSelectedDate(newValue);
                  }}
                  minDate={moment(MIN_DATE)}
                  maxDate={moment(mToday).add(2, "month")}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {showTimeline && (
        <div className="turnarounds-module-container tasks">
          <TurnaroundsModuleTasks
            taskInfoList={taskInfoList}
            turnaroundInfo={selectedTurnaroundInfo}
            timezone={airportTimezone}
          />
        </div>
      )}
      {showDetails && (
        <div className="turnarounds-module-container config">
          <TurnaroundsModuleConfig
            turnaroundInfo={selectedTurnaroundInfo}
            timezone={airportTimezone}
            gseTypes={gseTypes}
          />
        </div>
      )}
      {isNullOrUndefined(selectedTurnaroundInfo) && (
        <div className="turnarounds-module-container">
          <DataTable
            initialSort={TurnaroundsModuleColumns.Status.id}
            dataList={dataList}
            columnNames={[
              TurnaroundsModuleColumns.TurnaroundName,
              // TurnaroundsModuleColumns.Registration,
              TurnaroundsModuleColumns.Status,
              TurnaroundsModuleColumns.Landing,
              TurnaroundsModuleColumns.GateIn,
              TurnaroundsModuleColumns.GateOut,
              TurnaroundsModuleColumns.Takeoff,
              // TurnaroundsModuleColumns.TotalGate,
              TurnaroundsModuleColumns.TotalGround,
            ]}
            rowActions={{
              viewAction: (item) => {
                if (item.isCompleted) {
                  return (
                    <button
                      className="primary"
                      onClick={() => {
                        navigate(`/turnarounds/${item.uuid}`);
                      }}
                    >
                      {t("view_details")}
                    </button>
                  );
                } else {
                  return (
                    <button
                      className="alternate"
                      onClick={() => {
                        navigate(`/turnarounds/${item.uuid}`);
                      }}
                    >
                      {t("configure")}
                    </button>
                  );
                }
              },
            }}
            gridColumns={
              "minmax(0,220px) 1fr repeat(4, minmax(0,120px)) repeat(1, minmax(0,140px)) 140px"
            }
            searchQuery={searchQuery}
          />
        </div>
      )}
    </div>
  );
}

export default TurnaroundsModule;
