import "./styles.css";
import { isBlank, isNullOrUndefined } from "../../utils";
import { MAPINFO_USER_STATUS } from "../../mapUtils";
import UserPic, { UserPicSize, UserPicTheme } from "../UserBadge/UserPic";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { CHAT_CATEGORY_TYPES } from "../../commsUtils";
import { useTranslation } from "react-i18next";

function CrewInfo(props) {
  const { t } = useTranslation();
  const mainContext = useMainContext();
  const dispatch = useMainDispatchContext();
  const {
    user,
    details,
    mapStatus,
    hideMapStatus = false,
    userPicSize,
    userPicTheme,
    hideInfo = false,
    showActions = false,
    showAssignCrew = false,
    onAssignCrew,
    showUnassignCrew = false,
    showChatAction = false,
    isSavingUnassignment = false,
    onUnassignCrew,
  } = props;

  const { currentUser } = mainContext;
  const isInternal = currentUser?.isInternal;

  const currentMapStatus = hideMapStatus
    ? "hidden"
    : !isNullOrUndefined(mapStatus)
    ? `status-${mapStatus}`
    : `status-${MAPINFO_USER_STATUS.NONE}`;

  const fullName = `${user.firstName}${
    !isBlank(user.lastName) ? ` ${user.lastName}` : ""
  }`;

  return (
    <div className={`crew-info${showActions ? " show-actions" : ""}`}>
      <div className="crew-info-pic" data-user-uuid={user.uuid}>
        <UserPic
          size={userPicSize || UserPicSize.MEDIUM}
          theme={userPicTheme || UserPicTheme.DARK}
          user={user}
        />
        <div
          className={`crew-info-status ${currentMapStatus} ${
            userPicTheme || UserPicTheme.DARK
          }`}
        ></div>
      </div>
      <div className="crew-info-label">
        {!hideInfo && (
          <>
            <div className="crew-info-name">{fullName}</div>
            <div className="crew-info-detail">{details}</div>
          </>
        )}
      </div>
      {showActions && (
        <div className="crew-info-actions">
          {showChatAction && !isInternal && (
            <div>
              <button
                className="outlined"
                onClick={(e) => {
                  dispatch({
                    type: "setSelectedChatRequest",
                    value: {
                      type: CHAT_CATEGORY_TYPES.DIRECT,
                      userUuid: user.uuid,
                    },
                  });
                  e.stopPropagation();
                }}
              >
                {t("chat")}
              </button>
            </div>
          )}
          {showUnassignCrew && (
            <div>
              <button
                className="danger-outline"
                onClick={(e) => {
                  onUnassignCrew(user);
                  e.stopPropagation();
                }}
                disabled={isSavingUnassignment}
              >
                {t("unassign")}
              </button>
            </div>
          )}
          {showAssignCrew && (
            <div>
              <button
                className="outlined"
                onClick={(e) => {
                  onAssignCrew(user);
                  e.stopPropagation();
                }}
              >
                {t("resolve")}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default CrewInfo;
