import "./styles.css";
import { useCallback, useRef, useEffect, useState, useMemo } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import Supercluster from "supercluster";
import {
  dedupeByField,
  deepCopy,
  filterList,
  getByFieldValue,
  getByUuid,
  getMapItemByFieldValue,
  isEmptyList,
  isNullOrUndefined,
  sortByField,
} from "../../utils";
import {
  getAirportDetailByUuid,
  getTurnaroundDetails,
  getTurnaroundsSummary,
  getUsers,
  getVehicles,
  saveAircraftStand,
} from "../../api";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import {
  ANALYTICS_EVENTS,
  DEFAULT_TIMEZONE,
  MAP_ASSET_CATEGORIES,
  MAP_OVERLAY_PANEL_MODES,
  MAP_RIGHT_PANEL_MODES,
  MapStyles,
  OVERLAY_PANEL_MODES,
  POLLING_INTERVALS,
} from "../../constants";
import {
  FIT_MODES,
  MAPBOX_STANDARD_STYLE,
  MAPBOX_STYLE,
  MAPINFO_MARKER_TYPE,
  MAPINFO_STATUS,
  MAPINFO_STATUS_TEXT,
  MAP_ZOOM_LEVELS,
  MAP_ZOOM_PADDING,
  MAP_ZOOM_PRESETS,
  TIME_OF_DAY,
  addAircraftLabelMarker,
  addAirportMarker,
  addClusterMarker,
  addGeofenceMarkers,
  addOrUpdateMarker,
  addOrUpdateMarkerLabel,
  addPinMarker,
  getAircraftCenterOffset,
  getAircraftInfoForMap,
  getBoundingBoxCoordinates,
  getBoundingBoxForMarkers,
  getDistanceInMiles,
  getFlightByRegistration,
  getLayerForRoute,
  getLayerForTargetRoute,
  getMilesInPixels,
  getPositionSequenceForMarker,
  getSourceDataForAirport,
  getSourceDataForRoute,
  getSourceForRoute,
  getStrokeLayerForAirport,
  getTurnaroundInfoByRegistration,
  getTurnaroundOperationForGse,
  getTurnaroundOperationForUser,
  getUnassignedUserRecordForUser,
  getUserByUuid,
  getUserInfoForMap,
  getVehicleByTrackerId,
  getVehicleInfoForMap,
  getVehicleTypesFromVehicles,
  getWingspanInPixels,
  guessTimeOfDay,
  handleHover,
  isFilteredOnMap,
  isInTurnaroundScope,
  isWhitelistedOnMap,
  isWithinAirportBounds,
} from "../../mapUtils";
import moment from "moment-timezone";
import TurnaroundInfoPanel from "../Turnarounds/TurnaroundInfoPanel";
import TurnaroundOperationPanel from "../Turnarounds/TurnaroundOperationPanel";
import {
  ResourceActions,
  userHasResourceAction,
} from "../../resourceActionsUtils";
import GateChangePanel from "./GateChangePanel";
import {
  getTurnaroundInfo,
  getTurnaroundInfoForTurnaroundSummary,
  hasCriticalTurnarounds,
} from "../../turnaroundUtils";
import MapOverlayPanel from "./MapOverlayPanel";
import MapFilterButton from "./MapFilterButton";
import MapRecenterControl from "./MapRecenterControl";
import Keys from "../Keys";
import MapInfo from "./MapInfo";
import TurnaroundAlertsPanel from "../Turnarounds/TurnaroundAlertsPanel";
import ButtonSwitch from "../ButtonSwitch";
import { isUserOnMap } from "../../userUtils";
import { useTranslation } from "react-i18next";
import { SettingNames, SettingsManager } from "../../SettingsManager";
import LoadingIndicator from "../LoadingIndicator";
import AllocationsModule from "../Allocations/AllocationsModule";
import UnassignedCrewPanel from "../Turnarounds/UnassignedCrewPanel";
import CrewAssignmentModule from "../Allocations/CrewAssignmentModule";
import PinMarkerModal from "./PinMarkerModal";
import { CHAT_CATEGORY_TYPES } from "../../commsUtils";
import { useNavigate } from "react-router-dom";

function Map() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const {
    currentUser,
    dynamicEnv,
    positions,
    vehicles,
    users: usersList,
    flightsToRender,
    turnaroundsSummary: turnarounds,
    turnaroundsSummaryLoading: turnaroundsLoading,
    turnaroundDetails,
    gateChangeSaving,
    airportDetail,
    showOnMap,
    moveMapToPosition,
    searchQuery,
    moveAircraftRequest,
    isRefreshPaused,
    showMapInfo,
    showMapRoutes,
    pinMarker,
    mapFilters,
  } = mainContext;

  mapboxgl.accessToken = dynamicEnv?.mapBoxToken;
  const dynamicMapStyleEnabled =
    SettingsManager.getSetting(SettingNames.MAP_STYLE.name) ===
    MapStyles.DYNAMIC;
  const mapContainer = useRef(null);
  const mapRef = useRef(null);
  const mapMoveRef = useRef(null);
  const isInternal = currentUser?.isInternal;
  const airport = currentUser?.airport;
  const airportTimezone = !isNullOrUndefined(airport)
    ? airport.timezone
    : DEFAULT_TIMEZONE;
  const mNow = moment().tz(airportTimezone);
  const initialMapSettings = {
    lng: !isNullOrUndefined(airport)
      ? (airport.westLongitude + airport.eastLongitude) / 2
      : null,
    lat: !isNullOrUndefined(airport)
      ? (airport.northLatitude + airport.southLatitude) / 2
      : null,
    zoom: MAP_ZOOM_LEVELS.AIRPORT,
    bearing: 0,
    pitch: 0,
  };
  const [lng, setLng] = useState(initialMapSettings.lng);
  const [lat, setLat] = useState(initialMapSettings.lat);
  const [zoom, setZoom] = useState(initialMapSettings.zoom);
  const [bearing, setBearing] = useState(initialMapSettings.bearing);
  const [pitch, setPitch] = useState(initialMapSettings.pitch);
  const [point, setPoint] = useState({});
  const [lngLat, setLngLat] = useState({});
  const [showMapClusters] = useState(true);
  const [showMapRadius, setShowMapRadius] = useState(null);
  const [showMapStands, setShowMapStands] = useState(false);
  const [mapRoutes, setMapRoutes] = useState({});
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedTurnaround, setSelectedTurnaround] = useState(null);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [selectedVehicleUuid, setSelectedVehicleUuid] = useState(null);
  const [selectedUserUuid, setSelectedUserUuid] = useState(null);
  const [selectedUnassignedUserRecord, setSelectedUnassignedUserRecord] =
    useState(null);
  const [panelOpen, setPanelOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [gateChangePanelOpen, setGateChangePanelOpen] = useState(false);
  const [rightPanelOpen, setRightPanelOpen] = useState(false);
  const [rightPanelMode, setRightPanelMode] = useState(
    MAP_RIGHT_PANEL_MODES.TIMELINE
  );
  const [fitToBoundsRequested, setFitToBoundsRequested] = useState(null);
  const [turnaroundUpdateRequested, setTurnaroundUpdateRequested] =
    useState(false);
  const [refreshSelectedContextRequested, setRefreshSelectedContextRequested] =
    useState(false);
  const [zoomToPointRequested, setZoomToPointRequested] = useState(null);
  const [airportCenter, setAirportCenter] = useState(null);
  const [timeOfDay, setTimeOfDay] = useState(guessTimeOfDay(mNow));
  const [isMapboxStandardStyleEnabled] = useState(dynamicMapStyleEnabled);
  const [isBasemapLoaded, setIsBasemapLoaded] = useState(false);
  const [aircraftDistanceFromAirport, setAircraftDistanceFromAirport] =
    useState(null);
  const [showMapLoading, setShowMapLoading] = useState(true);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [renderedMapMarkers, setRenderedMapMarkers] = useState(0);
  const [isPinMarkerOpen, setIsPinMarkerOpen] = useState(false);
  const [validMarkersWithinBounds, setValidMarkersWithinBounds] = useState([]);
  const [showMapLabels, setShowMapLabels] = useState(false);
  const markers = useRef({});
  const markerInfos = useRef({});
  const markersExtra = useRef({});
  const gates = !isNullOrUndefined(airportDetail) ? airportDetail.stands : [];

  const resetMapSelections = useCallback(
    (keepZoom) => {
      setSelectedMarker(null);
      setSelectedTurnaround(null);
      setSelectedOperation(null);
      setSelectedVehicleUuid(null);
      setSelectedUserUuid(null);
      togglePanels(false, false, false);
      if (!keepZoom) {
        if (zoom < MAP_ZOOM_LEVELS.AIRPORT) {
          resetMap(MAP_ZOOM_LEVELS.AIRPORT, airportCenter, true);
        } else {
          resetMap(MAP_ZOOM_LEVELS.TERMINAL);
        }
      }
    },
    [airportCenter, zoom]
  );

  // Clean up stuff for the map page
  useEffect(() => {
    return () => {
      dispatch({
        type: "setItemsToWhitelist",
        value: null,
      });
      dispatch({
        type: "setSearchbarDisabled",
        value: false,
      });
    };
  }, [dispatch]);

  // Reset search bar and the placeholder text
  // Only on updates to the mapFilters.mode
  useEffect(() => {
    const category = getMapItemByFieldValue(
      MAP_ASSET_CATEGORIES,
      "value",
      mapFilters.mode
    );

    dispatch({
      type: "setSearchQuery",
      value: {
        searchTerm: "",
        searchPlaceholder: category.searchPlaceholder,
      },
    });
  }, [dispatch, mapFilters.mode]);

  useEffect(() => {
    if (isNullOrUndefined(airport)) return () => {};
    if (isNullOrUndefined(mapRef.current)) {
      // initialize map only once
      const airportLat = !isNullOrUndefined(airport)
        ? (airport.northLatitude + airport.southLatitude) / 2
        : null;
      const airportLng = !isNullOrUndefined(airport)
        ? (airport.westLongitude + airport.eastLongitude) / 2
        : null;
      const airportCenter = [airportLng, airportLat];
      setAirportCenter(airportCenter);

      const mapStyle = isMapboxStandardStyleEnabled
        ? MAPBOX_STANDARD_STYLE
        : MAPBOX_STYLE;

      mapRef.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: mapStyle,
        center: airportCenter,
        zoom: zoom,
        trackResize: true,
      });

      const mapBox = mapRef.current;
      mapBox.on("move", () => {
        // Use debounce to prevent excessive events
        if (mapMoveRef.current) {
          clearTimeout(mapMoveRef.current);
        }
        mapMoveRef.current = setTimeout(() => {
          setLng(mapBox.getCenter().lng);
          setLat(mapBox.getCenter().lat);
          setZoom(mapBox.getZoom());
          setBearing(mapBox.getBearing());
          setPitch(mapBox.getPitch());
        }, 10);
      });
      mapBox.on("mousemove", (e) => {
        setPoint(e?.point);
        setLngLat(e?.lngLat);
      });
      mapBox.on("click", (e) => {
        resetMapSelections(true);
      });

      if (isMapboxStandardStyleEnabled) {
        mapBox.on("style.load", () => {
          try {
            // We should be able to call getConfig, but seems like it's not supported???
            mapBox.setConfigProperty(
              "basemap",
              "showPointOfInterestLabels",
              false
            );
            mapBox.setConfigProperty("basemap", "showTransitLabels", false);
            mapBox.setConfigProperty("basemap", "showPlaceLabels", false);

            // TODO: Hiding road labels also hides stand labels, need alternate way
            // to hide road labels but show stand labels
            // mapBox.setConfigProperty("basemap", "showRoadLabels", false);
            mapBox.setConfigProperty("basemap", "lightPreset", timeOfDay);
            setIsBasemapLoaded(true);
          } catch (ignored) {
            console.log(ignored);
          }
        });
      }
      // Add zoom and rotation controls to the map.
      const navControl = new mapboxgl.NavigationControl();
      mapBox.addControl(navControl, "bottom-right");
    }
  }, [
    dispatch,
    airport,
    zoom,
    resetMapSelections,
    isMapboxStandardStyleEnabled,
    timeOfDay,
  ]);

  const resetMap = (zoomLevel, centerPoint, disableEasing) => {
    const mapBox = mapRef.current;
    mapBox.resize();
    const mapSettings = {
      bearing: 0,
      pitch: 0,
      zoom: zoomLevel,
      duration: disableEasing ? 0 : 1000,
    };
    if (!isNullOrUndefined(centerPoint)) {
      mapSettings.center = centerPoint;
    }
    mapBox.easeTo(mapSettings);
    mapBox.triggerRepaint();
  };

  // Handles zooming to a point on the map
  useEffect(() => {
    if (!isNullOrUndefined(zoomToPointRequested)) {
      const mapBox = mapRef.current;
      mapBox.resize();
      let zoomTo = mapBox.getZoom() + 1;
      if (zoomTo < MAP_ZOOM_LEVELS.TERMINAL) {
        zoomTo = MAP_ZOOM_LEVELS.TERMINAL;
      }

      const mapSettings = {
        bearing: 0,
        pitch: 0,
        zoom: zoomTo,
        duration: 1000,
        center: zoomToPointRequested,
      };
      mapBox.easeTo(mapSettings);
      mapBox.triggerRepaint();
    }
    setZoomToPointRequested(null);
  }, [zoomToPointRequested]);

  // Handles repositioning to a new position on the map
  useEffect(() => {
    if (isNullOrUndefined(mapRef?.current)) return () => {};
    if (isNullOrUndefined(moveMapToPosition)) return () => {};
    let latitude = moveMapToPosition.latitude;
    let longitude = moveMapToPosition.longitude;
    let moveToMarker = moveMapToPosition.moveToMarker;
    const markerType = !isNullOrUndefined(moveToMarker)
      ? moveToMarker.mapInfo.markerType
      : null;
    mapRef.current.resize();
    const prevZoom = mapRef.current.getZoom();
    const prevPitch = mapRef.current.getPitch();
    const prevCenter = mapRef.current.getCenter();
    let zoom = 0;
    let pitch = 0;
    let zoomToAircraftAirport = false;
    let distanceFromAirport = null;
    if (markerType === MAPINFO_MARKER_TYPE.AIRCRAFT) {
      const onGround = moveToMarker.mapInfo.position.onGround;
      const isSnappedToStand = !isNullOrUndefined(
        moveToMarker.mapInfo?.turnaroundInfo?.snappedStand
      );
      let optimalZoomLevel = MAP_ZOOM_LEVELS.AIRCRAFT;
      if (!onGround) {
        optimalZoomLevel = MAP_ZOOM_LEVELS.INFLIGHT;
      } else if (onGround && !isSnappedToStand) {
        optimalZoomLevel = MAP_ZOOM_LEVELS.AIRPORT;
      }
      // Temp set the map to a known location to compute the adjusted
      mapRef.current.setZoom(optimalZoomLevel);
      mapRef.current.setPitch(0);
      mapRef.current.setCenter([longitude, latitude]);
      zoom = optimalZoomLevel;
      pitch = 25;
      // Attempt to map center to aircraft using adjusted center position
      const wsInPixels = getWingspanInPixels(optimalZoomLevel, latitude);
      const heading = moveToMarker.mapInfo.position.heading;
      const centerOffset = getAircraftCenterOffset(heading, wsInPixels);
      const centerPointXY = mapRef.current.project([longitude, latitude]);
      const centerWithOffsetInPixels = [
        centerPointXY.x + centerOffset.x,
        centerPointXY.y - centerOffset.y,
      ];
      const adjustedLngLat = mapRef.current.unproject(centerWithOffsetInPixels);
      if (!isNaN(adjustedLngLat.lat) && !isNaN(adjustedLngLat.lng)) {
        latitude = adjustedLngLat.lat;
        longitude = adjustedLngLat.lng;
      }
      // Reset map back from the temp
      mapRef.current.setZoom(prevZoom);
      mapRef.current.setPitch(prevPitch);
      mapRef.current.setCenter(prevCenter);

      // Zoom to aircraft/airport level if appropriate
      distanceFromAirport =
        !onGround && !isNullOrUndefined(moveToMarker.mapInfo.position)
          ? getDistanceInMiles(airportCenter, [
              moveToMarker.mapInfo.position.longitude,
              moveToMarker.mapInfo.position.latitude,
            ])
          : -1;
      zoomToAircraftAirport =
        distanceFromAirport > MAP_ZOOM_PRESETS.APPROACH &&
        distanceFromAirport < MAP_ZOOM_PRESETS.MAX;
    } else if (
      markerType === MAPINFO_MARKER_TYPE.VEHICLE ||
      markerType === MAPINFO_MARKER_TYPE.USER
    ) {
      // Temp set the map to a known location to compute the adjusted
      mapRef.current.setZoom(MAP_ZOOM_LEVELS.VEHICLE);
      mapRef.current.setPitch(0);
      mapRef.current.setCenter([longitude, latitude]);
      zoom = MAP_ZOOM_LEVELS.VEHICLE;
      pitch = 25;
      if (panelOpen) {
        // Shift over to the right since the panel is open
        const pixelsToShift =
          mapRef.current.getContainer().getBoundingClientRect().width / 4;
        const centerPointXY = mapRef.current.project([longitude, latitude]);
        centerPointXY.x = rightPanelOpen
          ? centerPointXY.x
          : centerPointXY.x - pixelsToShift / 2;

        const adjustedLngLat = mapRef.current.unproject([
          centerPointXY.x,
          centerPointXY.y,
        ]);
        if (!isNaN(adjustedLngLat.lat) && !isNaN(adjustedLngLat.lng)) {
          latitude = adjustedLngLat.lat;
          longitude = adjustedLngLat.lng;
        }
      }
      // Reset map back from the temp
      mapRef.current.setZoom(prevZoom);
      mapRef.current.setPitch(prevPitch);
      mapRef.current.setCenter(prevCenter);
    } else {
      zoom = MAP_ZOOM_LEVELS.AIRPORT;
      pitch = 0;
    }

    if (zoomToAircraftAirport) {
      setTimeout(() => {
        mapRef.current.resize();
        mapRef.current.fitBounds(
          [
            [
              moveToMarker.mapInfo.position.longitude,
              moveToMarker.mapInfo.position.latitude,
            ],
            airportCenter,
          ],
          {
            padding: MAP_ZOOM_PADDING,
            animate: false,
          }
        );
      }, 200);
    } else {
      setTimeout(() => {
        mapRef.current.resize();
        mapRef.current.easeTo({
          bearing: 0,
          center: [longitude, latitude],
          duration: 500,
          zoom: zoom,
          pitch: pitch,
          padding: MAP_ZOOM_PADDING,
        });
      }, 200);
    }
    dispatch({
      type: "setMoveMapToPosition",
      value: null,
    });
    setAircraftDistanceFromAirport(distanceFromAirport);
  }, [dispatch, moveMapToPosition, panelOpen, rightPanelOpen, airportCenter]);

  const togglePanels = (openPanel, openDetailsPanel, openRightPanel) => {
    setPanelOpen(openPanel);
    setDetailsOpen(openDetailsPanel);
    setRightPanelOpen(openRightPanel);
    if (!openRightPanel) {
      // Default panel mode
      setRightPanelMode(MAP_RIGHT_PANEL_MODES.TIMELINE);
    }
  };

  useEffect(() => {
    getVehicles(dispatch);
    getUsers(dispatch);
    getTurnaroundsSummary(dispatch);
    if (!isNullOrUndefined(currentUser)) {
      getAirportDetailByUuid(dispatch, { uuid: currentUser?.airport.uuid });
    }
  }, [dispatch, currentUser]);

  useEffect(() => {
    if (
      turnaroundUpdateRequested &&
      !isNullOrUndefined(turnarounds) &&
      !isNullOrUndefined(selectedTurnaround) &&
      !turnaroundsLoading
    ) {
      // When turnaroundUpdateRequested update the selected turnaround data
      let updatedTurnaround = false;
      for (let i = 0; !updatedTurnaround && i < turnarounds.length; i++) {
        const turnaround = turnarounds[i];
        if (turnaround.uuid === selectedTurnaround.uuid) {
          setSelectedTurnaround(
            getTurnaroundInfoForTurnaroundSummary(turnaround)
          );
          updatedTurnaround = true;
        }
      }
      setTurnaroundUpdateRequested(false);
    }
  }, [
    turnaroundUpdateRequested,
    turnarounds,
    turnaroundsLoading,
    selectedTurnaround,
  ]);

  // Fetch the most updated version of the selected turnaround
  useEffect(() => {
    if (isRefreshPaused) return () => {};
    if (!refreshSelectedContextRequested) return () => {};
    if (isNullOrUndefined(selectedTurnaround)) return () => {};
    if (isNullOrUndefined(turnarounds)) return () => {};
    getTurnaroundDetails(dispatch, selectedTurnaround);
    setRefreshSelectedContextRequested(false);
  }, [
    dispatch,
    refreshSelectedContextRequested,
    turnarounds,
    selectedTurnaround,
    selectedOperation,
    isRefreshPaused,
  ]);

  // Load the most updated version of the selected turnaround
  useEffect(() => {
    if (
      !isNullOrUndefined(turnaroundDetails) &&
      !isNullOrUndefined(selectedTurnaround) &&
      selectedTurnaround.uuid === turnaroundDetails.uuid
    ) {
      const turnaroundInfo = getTurnaroundInfo(turnaroundDetails);
      const turnaroundOperationForGse = !isNullOrUndefined(selectedVehicleUuid)
        ? getTurnaroundOperationForGse(turnaroundInfo, selectedVehicleUuid)
        : null;
      setSelectedTurnaround(turnaroundInfo);
      if (!isNullOrUndefined(selectedOperation?.uuid)) {
        // If there was a selected GSE then refresh the associated operation
        const operationToRefreshUuid =
          !isNullOrUndefined(turnaroundOperationForGse) &&
          turnaroundOperationForGse.uuid !== selectedOperation.uuid
            ? turnaroundOperationForGse.uuid
            : selectedOperation.uuid;

        const refreshedOperation = getByUuid(
          turnaroundInfo.operations,
          operationToRefreshUuid
        );
        if (!isNullOrUndefined(refreshedOperation)) {
          setSelectedOperation(refreshedOperation);
        }
      } else if (!isNullOrUndefined(selectedVehicleUuid)) {
        // Support for selecting the associated operation for GSE
        if (!isNullOrUndefined(turnaroundOperationForGse)) {
          setSelectedOperation(turnaroundOperationForGse);
          togglePanels(true, true, false);
        }
      } else if (!isNullOrUndefined(selectedUserUuid)) {
        // Support for selecting the associated operation for USER
        const turnaroundOperationForUser = getTurnaroundOperationForUser(
          turnaroundInfo,
          selectedUserUuid
        );
        if (!isNullOrUndefined(turnaroundOperationForUser)) {
          setSelectedOperation(turnaroundOperationForUser);
          togglePanels(true, true, false);
        }
        // If there is no associated operation, the userRecord could be in the unassigned bucket
        const unassignedUserRecordForUser = getUnassignedUserRecordForUser(
          turnaroundInfo,
          selectedUserUuid
        );
        if (!isNullOrUndefined(unassignedUserRecordForUser)) {
          setSelectedUnassignedUserRecord(unassignedUserRecordForUser);
          togglePanels(true, true, rightPanelOpen);
        }
      }

      // Cleanup
      dispatch({
        type: "setTurnaroundDetails",
        value: null,
      });
    }
  }, [
    dispatch,
    turnaroundDetails,
    selectedTurnaround,
    selectedOperation,
    selectedVehicleUuid,
    selectedUserUuid,
    isRefreshPaused,
    rightPanelOpen,
  ]);

  useEffect(() => {
    // Interval for refreshing the selected turnaround
    const intervals = [];

    if (POLLING_INTERVALS.TURNAROUNDS > 0) {
      const interval = setInterval(() => {
        setRefreshSelectedContextRequested(true);
      }, POLLING_INTERVALS.TURNAROUNDS);
      intervals.push(interval);
    }
    return () => {
      for (let i = 0; i < intervals.length; i++) {
        clearInterval(intervals[i]);
      }
    };
  }, []);

  useEffect(() => {
    // Interval for the time of day
    if (
      POLLING_INTERVALS.TIME_OF_DAY > 0 &&
      !isNullOrUndefined(airportTimezone)
    ) {
      const interval = setInterval(() => {
        const updatedTimeOfDay = guessTimeOfDay(moment().tz(airportTimezone));
        setTimeOfDay(updatedTimeOfDay);
      }, POLLING_INTERVALS.TIME_OF_DAY);

      return () => {
        clearInterval(interval);
      };
    }
  }, [airportTimezone]);

  useEffect(() => {
    // When time of day changes set on the map
    const mapBox = mapRef.current;
    if (
      isBasemapLoaded &&
      !isNullOrUndefined(timeOfDay) &&
      !isNullOrUndefined(mapBox) &&
      isMapboxStandardStyleEnabled
    ) {
      mapBox.setConfigProperty("basemap", "lightPreset", timeOfDay);
    }
  }, [timeOfDay, isMapboxStandardStyleEnabled, isBasemapLoaded]);

  // Handle reset panel states
  useEffect(() => {
    if (isNullOrUndefined(selectedTurnaround)) {
      // Reset
      setGateChangePanelOpen(false);
      setSelectedOperation(null);
      setSelectedUnassignedUserRecord(null);
      setSelectedUserUuid(null);
    }
  }, [selectedTurnaround]);

  // Handle request to move the aircraft
  useEffect(() => {
    if (
      !isNullOrUndefined(moveAircraftRequest) &&
      !isNullOrUndefined(selectedTurnaround)
    ) {
      setSelectedOperation(null);
      togglePanels(true, false, false);
      setGateChangePanelOpen(true);
      // Clear the request
      dispatch({
        type: "setMoveAircraftRequest",
        value: null,
      });
      // Clear the reminder
      dispatch({
        type: "setMoveAircraftReminder",
        value: null,
      });
    }
  }, [dispatch, moveAircraftRequest, selectedTurnaround]);

  // Handle when move aircraft modal opens/closes
  useEffect(() => {
    dispatch({
      type: "setIsMoveAircraftPanelOpen",
      value: gateChangePanelOpen,
    });
  }, [dispatch, gateChangePanelOpen]);

  // Handle clicking on and deeplinking/showing items on the map
  useEffect(() => {
    const hasPositions = !isNullOrUndefined(positions);
    const hasMarkers = renderedMapMarkers > 0;
    // !isNullOrUndefined(markerInfos?.current) &&
    // Object.keys(markerInfos.current).length > 0;
    const hasShowOnMap = !isNullOrUndefined(showOnMap);

    if (!hasPositions || !hasMarkers) {
      if (hasShowOnMap) {
        // Can't deeplink yet, show progress
        setShowMapLoading(true);
      }
      return () => {};
    }

    if (hasShowOnMap) {
      let markerId = null;
      if (showOnMap.markerType === MAPINFO_MARKER_TYPE.VEHICLE) {
        markerId = showOnMap.item?.uuid;
      } else if (showOnMap.markerType === MAPINFO_MARKER_TYPE.AIRCRAFT) {
        const aircraftRegistration = !isNullOrUndefined(
          showOnMap.item?.registration
        )
          ? showOnMap.item?.registration
          : showOnMap.item?.aircraft?.registration;
        markerId = aircraftRegistration;
      } else if (showOnMap.markerType === MAPINFO_MARKER_TYPE.USER) {
        markerId = showOnMap.item?.uuid;
      }
      const hasMarkerId = !isNullOrUndefined(markerId);
      const markerInfo = hasMarkerId ? markerInfos.current[markerId] : null;

      // Special case for vehicles, don't show turnaround
      // Unless the marker type is complete or in-progress
      // Markers will only have complete status when in-context of the turnaround
      const ignoreTurnaround =
        showOnMap.markerType === MAPINFO_MARKER_TYPE.VEHICLE &&
        markerInfo?.mapInfo.status !== MAPINFO_STATUS.COMPLETE &&
        markerInfo?.mapInfo.status !== MAPINFO_STATUS.IN_PROGRESS;

      if (!isNullOrUndefined(markerInfo)) {
        setSelectedMarker(markerInfo);
        const hasWhitelist = !isEmptyList(mapFilters?.whitelist);
        const isLocateMode = showOnMap.isLocateMode;
        if (hasWhitelist || isLocateMode) {
          // Disregard the turnaround
          if (showOnMap.markerType === MAPINFO_MARKER_TYPE.VEHICLE) {
            setSelectedUserUuid(null);
            setSelectedVehicleUuid(markerInfo.uuid);
          } else if (showOnMap.markerType === MAPINFO_MARKER_TYPE.USER) {
            setSelectedVehicleUuid(null);
            setSelectedUserUuid(markerInfo.uuid);
          }
        } else if (!isNullOrUndefined(markerInfo.mapInfo.turnaroundInfo)) {
          let shouldOpenDetails = false;
          let shouldOpenRightPanel = false;
          if (!ignoreTurnaround) {
            // The marker has an associated turnaround so select it
            setSelectedTurnaround(markerInfo.mapInfo.turnaroundInfo);
            setRefreshSelectedContextRequested(true);
            // NOTE: Do not open timeline by default
            // const turnaroundPhase = getCurrentTurnaroundPhase(
            //   markerInfo.mapInfo.turnaroundInfo
            // );
            // shouldOpenRightPanel =
            //   turnaroundPhase === TurnaroundPhaseType.TURNAROUND;
          } else {
            setSelectedTurnaround(null);
          }

          // Set the Vehicle or User here and try to load
          // the associated operation after fetching full details
          if (showOnMap.markerType === MAPINFO_MARKER_TYPE.VEHICLE) {
            setSelectedUserUuid(null);
            setSelectedVehicleUuid(markerInfo.uuid);
            shouldOpenDetails = true;
          } else if (showOnMap.markerType === MAPINFO_MARKER_TYPE.USER) {
            setSelectedVehicleUuid(null);
            setSelectedUserUuid(markerInfo.uuid);
            shouldOpenDetails = true;
          }
          togglePanels(true, shouldOpenDetails, shouldOpenRightPanel);
        }
        setShowMapLoading(false);
        setIsMapLoaded(true);
      } else {
        dispatch({
          type: "setGlobalError",
          globalError: t("flight_position_not_available"),
        });
      }

      // Cleanup
      dispatch({
        type: "setShowOnMap",
        value: null,
      });
    } else {
      setShowMapLoading(false);
      setIsMapLoaded(true);
    }
  }, [dispatch, t, showOnMap, positions, mapFilters, renderedMapMarkers]);

  useEffect(() => {
    if (
      !isNullOrUndefined(selectedVehicleUuid) &&
      !isNullOrUndefined(markerInfos)
    ) {
      const vehicleMarker = markerInfos.current[selectedVehicleUuid];
      if (!isNullOrUndefined(vehicleMarker)) {
        setSelectedMarker(vehicleMarker);
      }
    }
  }, [selectedVehicleUuid]);

  useEffect(() => {
    if (
      !isNullOrUndefined(selectedUserUuid) &&
      !isNullOrUndefined(markerInfos)
    ) {
      const userMarker = markerInfos.current[selectedUserUuid];
      if (!isNullOrUndefined(userMarker)) {
        setSelectedMarker(userMarker);
      }
    }
  }, [selectedUserUuid]);

  useEffect(() => {
    if (!isNullOrUndefined(selectedMarker)) {
      const latitude = selectedMarker.mapInfo.position.latitude;
      const longitude = selectedMarker.mapInfo.position.longitude;
      // moveMapToPosition(latitude, longitude, selectedMarker);
      dispatch({
        type: "setMoveMapToPosition",
        value: {
          latitude: latitude,
          longitude: longitude,
          moveToMarker: selectedMarker,
        },
      });
    }
  }, [dispatch, selectedMarker]);

  // Adds a marker for the airport (only once)
  useEffect(() => {
    const airportMarker = document.querySelector(".marker-airport");
    if (
      !isNullOrUndefined(airportDetail?.uuid) &&
      isNullOrUndefined(airportMarker)
    ) {
      addAirportMarker(mapboxgl, mapRef, {
        airport: airportDetail,
      });
    }
  }, [airportDetail]);

  useEffect(() => {
    const markerToRemove = markersExtra.current["pin_marker"];
    if (!isNullOrUndefined(markerToRemove)) {
      markerToRemove?.remove();
      delete markersExtra.current["pin_marker"];
    }
    if (!isNullOrUndefined(pinMarker)) {
      try {
        const markerToAdd = addPinMarker(mapboxgl, mapRef, pinMarker);
        markersExtra.current["pin_marker"] = markerToAdd;
      } catch (e) {
        dispatch({
          type: "setGlobalError",
          globalError: e.message || t("request_error_msg"),
        });
      }
    }
  }, [dispatch, pinMarker, t]);

  // Draws boundary around the airport
  useEffect(() => {
    const sourceId = "airport-source";
    const strokeLayerId = "airport-stroke-layer";
    if (isNullOrUndefined(mapRef?.current)) return () => {};
    const mapBox = mapRef.current;
    if (showMapInfo) {
      try {
        const existingLayer = mapBox.getLayer(strokeLayerId);
        if (!isNullOrUndefined(existingLayer)) return () => {};

        const geojsonSource = mapBox.getSource(sourceId);
        if (isNullOrUndefined(geojsonSource)) {
          const source = getSourceDataForAirport(airport);
          mapBox.addSource(sourceId, source);
        }
        const strokeLayer = getStrokeLayerForAirport(strokeLayerId, sourceId);
        mapBox.addLayer(strokeLayer);
      } catch (e) {
        console.log(e);
      }
    } else {
      // remove the layers
      if (!isNullOrUndefined(mapBox)) {
        try {
          const layer = mapBox.getLayer(strokeLayerId);
          if (!isNullOrUndefined(layer)) {
            mapBox.removeLayer(strokeLayerId);
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  }, [showMapInfo, airport]);

  useEffect(() => {
    const sourceId = "range-source";
    const layerId = "range-layer";
    if (!isNullOrUndefined(fitToBoundsRequested)) return () => {};
    if (isNullOrUndefined(mapRef?.current)) return () => {};
    const mapBox = mapRef.current;
    if (!isNullOrUndefined(mapBox)) {
      try {
        const layer = mapBox.getLayer(layerId);
        if (!isNullOrUndefined(layer)) {
          mapBox.removeLayer(layerId);
        }
      } catch (e) {
        console.log(e);
      }
    }
    if (
      !isNullOrUndefined(showMapRadius) &&
      !isNullOrUndefined(mapBox) &&
      showMapInfo
    ) {
      try {
        const geojsonSource = mapBox.getSource(sourceId);
        if (isNullOrUndefined(geojsonSource)) {
          mapBox.addSource(sourceId, {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  geometry: {
                    type: "Point",
                    coordinates: airportCenter,
                  },
                },
              ],
            },
          });
        }

        if (showMapRadius > 0) {
          const radiusInPixels = getMilesInPixels(zoom, lat, showMapRadius);
          const layerData = {
            id: layerId,
            type: "circle",
            source: sourceId,
            paint: {
              "circle-radius": radiusInPixels,
              "circle-color": "#1779da",
              "circle-opacity": 0.25,
            },
          };
          mapBox.addLayer(layerData);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [
    showMapRadius,
    airportCenter,
    zoom,
    lat,
    fitToBoundsRequested,
    showMapInfo,
  ]);

  // Handle setting the map zoom level to fit to specified boundaries
  useEffect(() => {
    if (isNullOrUndefined(fitToBoundsRequested?.fitToMode)) return () => {};
    if (!isMapLoaded) return () => {};
    const mapBox = mapRef.current;

    let bbox = null;
    const mapRadius = !isNullOrUndefined(showMapRadius)
      ? showMapRadius
      : MAP_ZOOM_PRESETS.STATION;
    if (
      fitToBoundsRequested.fitToMode === FIT_MODES.POINTS &&
      !isEmptyList(fitToBoundsRequested.points)
    ) {
      bbox = getBoundingBoxForMarkers(fitToBoundsRequested.points);
    } else if (fitToBoundsRequested.fitToMode === FIT_MODES.AIRPORT) {
      bbox = getBoundingBoxCoordinates(airportCenter, mapRadius);
    }

    if (!isNullOrUndefined(bbox)) {
      try {
        mapBox.fitBounds(bbox, {
          duration: 1000,
          maxDuration: 1000,
          padding: MAP_ZOOM_PADDING,
        });
      } catch (e) {
        // NOTE: Safety measure due to possible MapBox bug with padding
        console.log("DEBUG INFO:", e, bbox);
      }
      // Cleanup fitToBounds request
      setFitToBoundsRequested(null);
    }
  }, [fitToBoundsRequested, airportCenter, showMapRadius, isMapLoaded]);

  useEffect(() => {
    if (isNullOrUndefined(mapRef?.current)) return () => {};
    const mapBox = mapRef.current;

    let idValue = null;
    let showTargetRoute = false;
    if (showMapRoutes && !isNullOrUndefined(selectedMarker)) {
      if (selectedMarker.mapInfo.markerType === MAPINFO_MARKER_TYPE.AIRCRAFT) {
        idValue = selectedMarker.registration;
        const onGround = selectedMarker.mapInfo?.position?.onGround;
        showTargetRoute = !onGround;
      } else if (
        selectedMarker.mapInfo.markerType === MAPINFO_MARKER_TYPE.VEHICLE
      ) {
        idValue = selectedMarker.trackerCode;
      }
    }

    // Remove all routes except for the selected one
    const keys = Object.keys(mapRoutes);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const idValues = mapRoutes[key];
      if (isNullOrUndefined(idValue) || idValue !== key) {
        try {
          if (!isNullOrUndefined(mapBox.getLayer(idValues.layerId))) {
            mapBox.removeLayer(idValues.layerId);
          }
        } catch (e) {
          console.log(e);
        }
        try {
          if (!isNullOrUndefined(mapBox.getSource(idValues.sourceId))) {
            mapBox.removeSource(idValues.sourceId);
          }
        } catch (e) {
          console.log(e);
        }
        if (showTargetRoute || isNullOrUndefined(idValue)) {
          try {
            if (!isNullOrUndefined(mapBox.getLayer(idValues.targetLayerId))) {
              mapBox.removeLayer(idValues.targetLayerId);
            }
          } catch (e) {
            console.log(e);
          }
          try {
            if (!isNullOrUndefined(mapBox.getSource(idValues.targetSourceId))) {
              mapBox.removeSource(idValues.targetSourceId);
            }
          } catch (e) {
            console.log(e);
          }
        }
        delete mapRoutes[key];
      }
    }
    if (!isNullOrUndefined(idValue)) {
      const sourceId = `route_data_${idValue}`;
      const layerId = `route_layer_${idValue}`;
      const targetSourceId = `route_target_data_${idValue}`;
      const targetLayerId = `route_target_layer_${idValue}`;
      const hasMappedRoutes = !isNullOrUndefined(mapRoutes[idValue]);

      const positionSequence = getPositionSequenceForMarker(
        positions,
        selectedMarker.mapInfo.markerType,
        idValue
      );

      if (!isNullOrUndefined(positionSequence)) {
        const positionSequenceList = dedupeByField(
          positionSequence,
          "timestamp"
        );
        sortByField(positionSequenceList, "timestamp");
        const aircraftToAirportSequence =
          positionSequenceList?.length > 0 && !isNullOrUndefined(airportCenter)
            ? [
                {
                  longitude: airportCenter[0],
                  latitude: airportCenter[1],
                },
                positionSequenceList[positionSequenceList.length - 1], // current position
              ]
            : null;

        if (hasMappedRoutes) {
          try {
            // update data only
            const data = getSourceDataForRoute(positionSequenceList);
            const geojsonSource = mapBox.getSource(sourceId);
            geojsonSource.setData(data);

            if (
              showTargetRoute &&
              !isNullOrUndefined(aircraftToAirportSequence)
            ) {
              const targetData = getSourceDataForRoute(
                aircraftToAirportSequence
              );
              const targetGeojsonSource = mapBox.getSource(targetSourceId);
              targetGeojsonSource.setData(targetData);
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          try {
            const source = getSourceForRoute(positionSequenceList);
            mapBox.addSource(sourceId, source);
            const layer = getLayerForRoute(layerId, sourceId);
            mapBox.addLayer(layer);

            if (
              showTargetRoute &&
              !isNullOrUndefined(aircraftToAirportSequence)
            ) {
              const targetSource = getSourceForRoute(aircraftToAirportSequence);
              mapBox.addSource(targetSourceId, targetSource);
              const targetLayer = getLayerForTargetRoute(
                targetLayerId,
                targetSourceId
              );
              mapBox.addLayer(targetLayer);
            }
          } catch (e) {
            console.log(e);
          }
          mapRoutes[idValue] = showTargetRoute
            ? {
                layerId: layerId,
                sourceId: sourceId,
                targetSourceId: targetSourceId,
                targetLayerId: targetLayerId,
              }
            : { layerId: layerId, sourceId: sourceId };
          setMapRoutes(mapRoutes);
        }
      }
    }
  }, [mapRoutes, showMapRoutes, selectedMarker, positions, airportCenter]);

  // Main effect for rendering the markers
  useEffect(() => {
    if (isNullOrUndefined(positions)) {
      return () => {};
    }
    const groundVehicles = positions.groundVehicles;
    const users = positions.users;
    const aircrafts = positions.aircrafts;
    const mapZoom = mapRef?.current?.getZoom();
    const shouldClusterMarkers =
      showMapClusters && mapZoom < MAP_ZOOM_LEVELS.TERMINAL;
    const isTurnaroundSelected = !isNullOrUndefined(selectedTurnaround);

    // Click handler for map markers
    const handleMarkerClick = (markerInfo) => {
      setSelectedMarker(markerInfo);

      dispatch({
        type: "setShowOnMap",
        value: {
          markerType: markerInfo.mapInfo.markerType,
          item: markerInfo,
        },
      });
      if (!isTurnaroundSelected) {
        let modeUpdated = null;
        if (markerInfo.mapInfo.markerType === MAPINFO_MARKER_TYPE.USER) {
          modeUpdated = OVERLAY_PANEL_MODES.CREW.value;
        } else if (
          markerInfo.mapInfo.markerType === MAPINFO_MARKER_TYPE.VEHICLE
        ) {
          modeUpdated = OVERLAY_PANEL_MODES.VEHICLES.value;
        }
        if (!isNullOrUndefined(modeUpdated)) {
          const mapFilterToUpdate = deepCopy(mapFilters);
          mapFilterToUpdate.mode = modeUpdated;
          dispatch({
            type: "setMapFilters",
            value: mapFilterToUpdate,
          });
        }
      }
    };

    dispatch({
      type: "setSearchbarDisabled",
      value: isTurnaroundSelected,
    });
    // Create map markers for the supported types
    const activeMarkers = {};
    const mapPoints = [];
    const geofenceMarkers = [];
    let unclusteredMarkers = {};

    // For use with filtering
    const turnaroundInfoList = turnarounds?.map((item) =>
      getTurnaroundInfoForTurnaroundSummary(item)
    );
    const hasCritical = hasCriticalTurnarounds(turnaroundInfoList);

    // For use with fitToBounds (when switching between tabs)
    const markersToFit = [];

    if (!isNullOrUndefined(aircrafts)) {
      for (let i = 0; i < aircrafts.length; i++) {
        const aircraft = aircrafts[i];
        const flightInfo = !isNullOrUndefined(aircraft)
          ? getFlightByRegistration(flightsToRender, aircraft?.registration)
          : null;
        const turnaroundInfo = getTurnaroundInfoByRegistration(
          turnarounds,
          aircraft.registration
        );
        const aircraftInfo = !isNullOrUndefined(flightInfo)
          ? getAircraftInfoForMap(flightInfo, aircraft, turnaroundInfo)
          : null;
        const isInSelectedTurnaroundContext = isInTurnaroundScope(
          selectedTurnaround,
          selectedOperation,
          aircraftInfo
        );
        const isFiltered = isFilteredOnMap(
          MAPINFO_MARKER_TYPE.AIRCRAFT,
          turnaroundInfo,
          mapFilters,
          searchQuery,
          hasCritical
        );

        if (!isNullOrUndefined(aircraftInfo) && !isFiltered) {
          const existingMarker = markers.current[aircraft.registration];
          const labelKey = `label_${aircraft.registration}`;
          const existingLabelMarker = markers.current[labelKey];
          const markerOptions = {
            type: MAPINFO_MARKER_TYPE.AIRCRAFT,
            markerInfo: aircraftInfo,
            existingMarker: existingMarker,
            selected: selectedMarker?.registration === aircraft.registration,
            isInSelectedTurnaroundContext: isInSelectedTurnaroundContext,
            clickHandler: () => {
              handleMarkerClick(aircraftInfo);
            },
            mouseoverHandler: () => {
              handleHover(true, aircraftInfo);
            },
            mouseoutHandler: () => {
              handleHover(false, aircraftInfo);
            },
          };
          const aircraftMarker = addOrUpdateMarker(
            mapboxgl,
            mapRef,
            markerOptions
          );
          // Aircraft have separate marker for label
          const aircraftLabelMarker = addAircraftLabelMarker(
            mapboxgl,
            mapRef,
            {
              ...markerOptions,
              existingMarker: existingLabelMarker,
            },
            airportTimezone
          );
          if (showMapInfo) {
            const gfMarkers = addGeofenceMarkers(mapboxgl, mapRef, {
              markerInfo: aircraftInfo,
            });
            geofenceMarkers.push.apply(geofenceMarkers, gfMarkers);
          }
          // Add aircraft main marker
          markers.current[aircraft.registration] = aircraftMarker;
          markerInfos.current[aircraft.registration] = aircraftInfo;
          activeMarkers[aircraft.registration] = aircraftMarker;

          // Add aircraft label marker
          markers.current[labelKey] = aircraftLabelMarker;
          markerInfos.current[labelKey] = aircraftLabelMarker;
          activeMarkers[labelKey] = aircraftLabelMarker;
          if (shouldClusterMarkers) {
            // Aircraft are always excluded from clusters
            unclusteredMarkers[aircraft.registration] = aircraftMarker;
            unclusteredMarkers[labelKey] = aircraftLabelMarker;
          }

          // Add the coordinate for fitToBounds IF snapped to a stand
          const isSnappedToStand = !isNullOrUndefined(
            aircraftInfo?.mapInfo?.turnaroundInfo?.snappedStand?.name
          );
          if (isSnappedToStand) {
            markersToFit.push([
              aircraftInfo.mapInfo.position.longitude,
              aircraftInfo.mapInfo.position.latitude,
            ]);
          }
        }
      }
    }

    if (!isNullOrUndefined(groundVehicles)) {
      for (let i = 0; i < groundVehicles.length; i++) {
        const groundVehicle = groundVehicles[i];
        const vehicle = getVehicleByTrackerId(
          vehicles,
          groundVehicle.trackerCode
        );

        const vehicleInfo = !isNullOrUndefined(vehicle)
          ? getVehicleInfoForMap(
              vehicle,
              groundVehicle,
              turnarounds,
              selectedTurnaround?.uuid
            )
          : null;
        const isInSelectedTurnaroundContext = isInTurnaroundScope(
          selectedTurnaround,
          selectedOperation,
          vehicleInfo
        );
        const hasWhitelist = !isEmptyList(mapFilters?.whitelist);
        const isWhitelisted = hasWhitelist
          ? isWhitelistedOnMap(vehicleInfo, mapFilters)
          : false;
        const isFiltered = isFilteredOnMap(
          MAPINFO_MARKER_TYPE.VEHICLE,
          vehicleInfo,
          mapFilters,
          searchQuery
        );

        const isAllowed = isWhitelisted || !isFiltered;
        if (!isNullOrUndefined(vehicle) && isAllowed) {
          if (isWhitelisted) {
            // Override mapStatus when not in context of turnaround or whitelisted (locate panel)
            if (vehicleInfo.mapInfo.status === MAPINFO_STATUS.COMPLETE) {
              vehicleInfo.mapInfo.status = MAPINFO_STATUS.AVAILABLE;
              vehicleInfo.mapInfo.statusText = MAPINFO_STATUS_TEXT.AVAILABLE;
            }
          }
          const existingMarker = markers.current[vehicleInfo.uuid];
          const labelKey = `label_${vehicleInfo.uuid}`;
          const existingLabelMarker = markers.current[labelKey];

          const vehicleMarker = addOrUpdateMarker(mapboxgl, mapRef, {
            type: MAPINFO_MARKER_TYPE.VEHICLE,
            markerInfo: vehicleInfo,
            existingMarker: existingMarker,
            selected: selectedMarker?.uuid === vehicleInfo.uuid,
            isInSelectedTurnaroundContext: isInSelectedTurnaroundContext,
            clickHandler: () => {
              handleMarkerClick(vehicleInfo);
            },
            mouseoverHandler: () => {
              handleHover(true, vehicleInfo);
            },
            mouseoutHandler: () => {
              handleHover(false, vehicleInfo);
            },
          });
          const vehicleMarkerLabel = addOrUpdateMarkerLabel(mapboxgl, mapRef, {
            type: MAPINFO_MARKER_TYPE.VEHICLE,
            markerInfo: vehicleInfo,
            existingMarker: existingLabelMarker,
            selected: selectedMarker?.uuid === vehicleInfo.uuid,
            isInSelectedTurnaroundContext: isInSelectedTurnaroundContext,
            clickHandler: () => {
              handleMarkerClick(vehicleInfo);
            },
          });

          // Add vehicle main marker
          markers.current[vehicleInfo.uuid] = vehicleMarker;
          markerInfos.current[vehicleInfo.uuid] = vehicleInfo;
          activeMarkers[vehicleInfo.uuid] = vehicleMarker;

          // Add vehicle label marker
          markers.current[labelKey] = vehicleMarkerLabel;
          markerInfos.current[labelKey] = vehicleInfo;
          activeMarkers[labelKey] = vehicleMarkerLabel;

          if (shouldClusterMarkers) {
            mapPoints.push({
              markerId: vehicleInfo.uuid,
              coordinates: [
                vehicleInfo.mapInfo.position.longitude,
                vehicleInfo.mapInfo.position.latitude,
              ],
            });
          }

          // Add the coordinate for fitToBounds
          markersToFit.push([
            vehicleInfo.mapInfo.position.longitude,
            vehicleInfo.mapInfo.position.latitude,
          ]);
        }
      }
    }
    if (!isNullOrUndefined(users)) {
      for (let i = 0; i < users.length; i++) {
        const user = users[i];
        const userFromList = getUserByUuid(usersList, user.uuid);
        const existingMarker = markers.current[user.uuid];
        const userInfo = getUserInfoForMap(
          userFromList,
          user,
          turnarounds,
          selectedTurnaround
        );

        const isInSelectedTurnaroundContext = isInTurnaroundScope(
          selectedTurnaround,
          selectedOperation,
          userInfo
        );

        const hasWhitelist = !isEmptyList(mapFilters?.whitelist);
        const isWhitelisted = hasWhitelist
          ? isWhitelistedOnMap(userInfo, mapFilters)
          : false;
        const isFiltered = isFilteredOnMap(
          MAPINFO_MARKER_TYPE.USER,
          userInfo,
          mapFilters,
          searchQuery
        );

        const isAllowed = isWhitelisted || !isFiltered;
        if (!isNullOrUndefined(userFromList) && isAllowed) {
          const labelKey = `label_${userInfo.uuid}`;
          const existingLabelMarker = markers.current[labelKey];
          const userMarker = addOrUpdateMarker(mapboxgl, mapRef, {
            type: MAPINFO_MARKER_TYPE.USER,
            markerInfo: userInfo,
            existingMarker: existingMarker,
            selected: selectedMarker?.uuid === user.uuid,
            isInSelectedTurnaroundContext: isInSelectedTurnaroundContext,
            clickHandler: () => {
              handleMarkerClick(userInfo);
            },
            mouseoverHandler: () => {
              handleHover(true, userInfo);
            },
            mouseoutHandler: () => {
              handleHover(false, userInfo);
            },
          });
          const userMarkerLabel = addOrUpdateMarkerLabel(mapboxgl, mapRef, {
            type: MAPINFO_MARKER_TYPE.USER,
            markerInfo: userInfo,
            existingMarker: existingLabelMarker,
            selected: selectedMarker?.uuid === userInfo.uuid,
            isInSelectedTurnaroundContext: isInSelectedTurnaroundContext,
            clickHandler: () => {
              handleMarkerClick(userInfo);
            },
          });
          // Add user main marker
          markers.current[userInfo.uuid] = userMarker;
          markerInfos.current[userInfo.uuid] = userInfo;
          activeMarkers[userInfo.uuid] = userMarker;

          // Add user label marker
          markers.current[labelKey] = userMarkerLabel;
          markerInfos.current[labelKey] = userInfo;
          activeMarkers[labelKey] = userMarkerLabel;

          if (shouldClusterMarkers) {
            mapPoints.push({
              markerId: userInfo.uuid,
              coordinates: [
                userInfo.mapInfo.position.longitude,
                userInfo.mapInfo.position.latitude,
              ],
            });
          }

          // Add the coordinate for fitToBounds
          markersToFit.push([
            userInfo.mapInfo.position.longitude,
            userInfo.mapInfo.position.latitude,
          ]);
        }
      }
    }

    let clustersToMap = [];
    if (shouldClusterMarkers && mapPoints.length > 0) {
      const sourceData = mapPoints.map((pointInfo) => {
        return {
          type: "Feature",
          properties: {
            markerId: pointInfo.markerId,
          },
          geometry: {
            type: "Point",
            coordinates: pointInfo.coordinates,
          },
        };
      });
      let clusterRadius = 40;
      if (mapZoom >= MAP_ZOOM_LEVELS.TERMINAL) {
        clusterRadius = 10;
      }
      const index = new Supercluster({ radius: clusterRadius });
      index.load(sourceData);
      const mapBounds = mapRef.current.getBounds();
      const clusters = index.getClusters(
        [
          mapBounds.getWest(),
          mapBounds.getSouth(),
          mapBounds.getEast(),
          mapBounds.getNorth(),
        ],
        mapRef.current.getZoom()
      );
      if (!isNullOrUndefined(clusters)) {
        for (let i = 0; i < clusters.length; i++) {
          const cluster = clusters[i];
          if (
            cluster?.properties?.cluster &&
            cluster?.properties?.point_count > 1
          ) {
            // This is a cluster point
            clustersToMap.push({
              count: cluster?.properties?.point_count,
              coordinates: cluster.geometry.coordinates,
            });
          } else {
            // This is a single point
            unclusteredMarkers[cluster?.properties.markerId] = cluster;
          }
        }
      }
    }

    // Remove any markers that are:
    // 1) no longer in scope
    // 2) in a cluster
    // 3) cluster and geofence markers
    const markerKeys = Object.keys(markers.current);
    if (!isNullOrUndefined(markerKeys)) {
      for (let i = 0; i < markerKeys.length; i++) {
        const markerKey = markerKeys[i];
        const isNotActiveScope = isNullOrUndefined(activeMarkers[markerKey]);
        const isClusterMarker = markerKey.indexOf("cluster_", 0) === 0;
        const hasClusters = clustersToMap.length > 0;
        const isClusteredMarker =
          hasClusters && isNullOrUndefined(unclusteredMarkers[markerKey]);
        const isGeofenceMarker = markerKey.indexOf("geofence_", 0) === 0;

        if (
          isNotActiveScope ||
          isClusterMarker ||
          isClusteredMarker ||
          isGeofenceMarker
        ) {
          const markerToRemove = markers.current[markerKey];
          markerToRemove.remove();
          delete markers.current[markerKey];
        }
      }
    }

    // Add any cluster markers
    if (clustersToMap.length > 0) {
      for (let i = 0; i < clustersToMap.length; i++) {
        const clusterToMap = clustersToMap[i];
        const clusterMarker = addClusterMarker(mapboxgl, mapRef, {
          ...clusterToMap,
          clickHandler: () => {
            setZoomToPointRequested(clusterToMap.coordinates);
          },
        });
        const clusterId = `cluster_${i}`;
        markers.current[clusterId] = clusterMarker;
        activeMarkers[clusterId] = clusterMarker;
      }
    }

    // Add any geofence markers
    if (geofenceMarkers?.length > 0) {
      for (let i = 0; i < geofenceMarkers.length; i++) {
        const gfMarker = geofenceMarkers[i];
        const gfId = `geofence_${i}`;
        markers.current[gfId] = gfMarker;
        activeMarkers[gfId] = gfMarker;
      }
    }

    // Fit to bounds if requested
    const validMarkersToFit = markersToFit.filter((item) =>
      isWithinAirportBounds(airport, {
        latitude: item[1],
        longitude: item[0],
      })
    );
    // Save this in case needed for zoom
    if (isMapLoaded && !isEmptyList(validMarkersToFit)) {
      setValidMarkersWithinBounds(validMarkersToFit);
    }
    if (isMapLoaded && mapFilters.fitToBounds) {
      setFitToBoundsRequested({
        fitToMode:
          mapFilters.mode.value === OVERLAY_PANEL_MODES.FLIGHTS.value
            ? FIT_MODES.AIRPORT
            : FIT_MODES.POINTS,
        points: validMarkersToFit,
      });

      // Clear fitToBounds request
      const mapFiltersUpdated = deepCopy(mapFilters);
      delete mapFiltersUpdated.fitToBounds;
      dispatch({
        type: "setMapFilters",
        value: mapFiltersUpdated,
      });
    }

    // Signal that map markers have been rendered
    const renderedCount = !isNullOrUndefined(markerKeys)
      ? markerKeys.length
      : 0;
    setRenderedMapMarkers(renderedCount);
  }, [
    dispatch,
    airport,
    airportTimezone,
    positions,
    vehicles,
    usersList,
    flightsToRender,
    turnarounds,
    zoom,
    bearing,
    selectedMarker,
    markerInfos,
    mapFilters,
    showMapClusters,
    showMapInfo,
    selectedTurnaround,
    selectedOperation,
    searchQuery,
    isMapLoaded,
  ]);

  const selectedAirline = !isNullOrUndefined(selectedTurnaround)
    ? selectedTurnaround.inboundFlightAirlineUuid ||
      selectedTurnaround.outboundFlightAirlineUuid
    : null;
  const showGateChangeAction = userHasResourceAction(
    currentUser,
    ResourceActions.EditStandAssignment,
    selectedAirline
  );
  const showOverlayPanel =
    isNullOrUndefined(selectedTurnaround) &&
    !isNullOrUndefined(turnarounds) &&
    turnarounds.length > 0;

  const vehicleTypes = getVehicleTypesFromVehicles(vehicles);

  async function handleAircraftStandChange(gateToChange) {
    if (
      !isNullOrUndefined(selectedTurnaround) &&
      !isNullOrUndefined(gateToChange)
    ) {
      const saveResponse = await saveAircraftStand(dispatch, {
        reg: selectedTurnaround.registration,
        snappedStandUuid: gateToChange.uuid,
      });
      if (saveResponse) {
        dispatch({
          type: "setAlertMessage",
          alertMessage: t("stand_changed_to_msg", { name: gateToChange.name }),
        });
        await getTurnaroundsSummary(dispatch);
        await getTurnaroundDetails(dispatch, selectedTurnaround);

        // Use the gateToChange info to construct a temp moveToMarker for reposition
        const { latitude, longitude, heading } = gateToChange;
        const tempMarker = {
          mapInfo: {
            markerType: MAPINFO_MARKER_TYPE.AIRCRAFT,
            position: {
              onGround: true,
              heading: heading,
            },
          },
        };
        dispatch({
          type: "setMoveMapToPosition",
          value: {
            latitude: latitude,
            longitude: longitude,
            moveToMarker: tempMarker,
          },
        });
        setTurnaroundUpdateRequested(true);
        //setRefreshPositionsRequested(true);
      }
      setGateChangePanelOpen(false);
    }
  }
  const disableToolbarActions = !isNullOrUndefined(selectedTurnaround);

  const escKeyAction = () => {
    dispatch({
      type: "setSearchQuery",
      value: {
        searchTerm: "",
      },
    });
    resetMapSelections(true);
  };
  const ctrlKeyActions = {
    m: () => {
      dispatch({
        type: "setShowMapInfo",
        value: !showMapInfo,
      });
    },
    s: () => {
      setShowMapStands((prev) => !prev);
    },
    r: () => {
      dispatch({
        type: "setShowMapRoutes",
        value: !showMapRoutes,
      });
    },
    p: () => {
      dispatch({
        type: "setIsRefreshPaused",
        value: !isRefreshPaused,
      });
    },
    l: () => {
      setIsPinMarkerOpen(true);
    },
    0: () => {
      setShowMapRadius(MAP_ZOOM_PRESETS.OFF);
    },
    1: () => {
      setTimeOfDay(TIME_OF_DAY.DAWN);
    },
    2: () => {
      setTimeOfDay(TIME_OF_DAY.DAY);
    },
    3: () => {
      setTimeOfDay(TIME_OF_DAY.DUSK);
    },
    4: () => {
      setTimeOfDay(TIME_OF_DAY.NIGHT);
    },
  };

  const mapInfo = [
    {
      label: `${t("time_of_day")} (ctrl+1-4)`,
      value: timeOfDay,
    },
    {
      label: t("center"),
      value: `${lng?.toFixed(5)}, ${lat?.toFixed(5)}`,
    },
    {
      label: t("zoom"),
      value: `${zoom.toFixed(2)}`,
    },
    {
      label: t("bearing"),
      value: `${bearing.toFixed(2)}`,
    },
    {
      label: t("pitch"),
      value: `${pitch.toFixed(2)}`,
    },
    {
      label: t("xy"),
      value: `${point.x}, ${point.y}`,
    },
    {
      label: t("lng_lat"),
      value: `${lngLat.lng?.toFixed(5)}, ${lngLat.lat?.toFixed(5)}`,
    },
    {
      label: `${t("routes")} (ctrl+r)`,
      value: `${showMapRoutes}`,
    },
    {
      label: `${t("stand_non_cap")} (ctrl+s)`,
      value: `${showMapStands}`,
    },
    {
      label: `${t("paused")} (ctrl+p)`,
      value: `${isRefreshPaused}`,
    },
    {
      label: `${t("pin_marker")} (ctrl+l)`,
      value: !isNullOrUndefined(pinMarker)
        ? `${pinMarker?.longitude?.toFixed(2)}, ${pinMarker?.latitude?.toFixed(
            2
          )}`
        : "",
    },
    {
      label: `${t("distance")}`,
      value: !isNullOrUndefined(aircraftDistanceFromAirport)
        ? `${Math.round(aircraftDistanceFromAirport)} ${t(
            "miles_abbr"
          ).toLowerCase()}`
        : t("na"),
    },
  ];

  const activeUsersList = useMemo(() => {
    return filterList(usersList, (item) => {
      return isUserOnMap(item, positions);
    });
  }, [usersList, positions]);

  const unassignedUserPanelOpen = !isNullOrUndefined(
    selectedUnassignedUserRecord
  );

  // Details is the operation
  const isDetailsPanelOpen =
    !gateChangePanelOpen &&
    !unassignedUserPanelOpen &&
    !isNullOrUndefined(selectedOperation) &&
    !isNullOrUndefined(selectedTurnaround) &&
    detailsOpen;

  // Info is the turnaround
  const isInfoPanelOpen =
    !gateChangePanelOpen &&
    !unassignedUserPanelOpen &&
    !isDetailsPanelOpen &&
    !isNullOrUndefined(selectedTurnaround);

  return (
    <div className={`map${showMapLabels ? " display-labels" : ""}`}>
      <div className="map-container">
        <div className="map-toolbar">
          <div className="map-toolbar-container">
            <div>
              <ButtonSwitch
                selectedOption={mapFilters.mode}
                mapFilters={mapFilters}
                onChange={(value) => {
                  resetMapSelections(true);
                  let eventName = null;
                  if (value === OVERLAY_PANEL_MODES.TURNAROUNDS.value) {
                    eventName = ANALYTICS_EVENTS.VIEWED_TURNAROUNDS_FROM_MAP;
                  } else if (value === OVERLAY_PANEL_MODES.CREW.value) {
                    eventName = ANALYTICS_EVENTS.VIEWED_USERS_FROM_MAP;
                  } else if (value === OVERLAY_PANEL_MODES.VEHICLES.value) {
                    eventName = ANALYTICS_EVENTS.VIEWED_GSES_FROM_MAP;
                  } else if (value === OVERLAY_PANEL_MODES.FLIGHTS.value) {
                    eventName = ANALYTICS_EVENTS.VIEWED_FLIGHTS_FROM_MAP;
                  }
                  const mapFilterToUpdate = deepCopy(mapFilters);
                  mapFilterToUpdate.mode = value;
                  dispatch({
                    type: "setMapFilters",
                    value: mapFilterToUpdate,
                  });

                  if (!isNullOrUndefined(eventName)) {
                    dispatch({
                      type: "setAnalyticsEvent",
                      value: {
                        eventName: eventName,
                      },
                    });
                  }
                }}
                labelValuePairs={MAP_OVERLAY_PANEL_MODES}
                disabled={disableToolbarActions}
                turnarounds={turnarounds}
              />
            </div>
            <div>
              <MapFilterButton
                mapFilters={mapFilters}
                onChange={(filters) => {
                  dispatch({
                    type: "setMapFilters",
                    value: filters,
                  });
                }}
                vehicleTypes={vehicleTypes}
                onMapLabelsChange={() => {
                  setShowMapLabels((prev) => !prev);
                }}
                showMapLabels={showMapLabels}
              />
            </div>
          </div>
        </div>
        <div className="panels-container">
          <div className="map-panel">
            {/** TODO: Remove right panel stuff */}
            <div
              className={`right-panel ${
                isNullOrUndefined(selectedTurnaround) || !rightPanelOpen
                  ? " not-visible"
                  : rightPanelMode === MAP_RIGHT_PANEL_MODES.CHAT ||
                    rightPanelMode === MAP_RIGHT_PANEL_MODES.CREW_ALLOCATIONS ||
                    rightPanelMode === MAP_RIGHT_PANEL_MODES.CREW_ASSIGNMENTS ||
                    rightPanelMode === MAP_RIGHT_PANEL_MODES.ADD_CREW_MEMBER
                  ? " full-module"
                  : ""
              }`}
            >
              <div
                className={`right-panel-content${
                  rightPanelMode === MAP_RIGHT_PANEL_MODES.TIMELINE
                    ? ""
                    : " not-visible"
                }`}
              >
                <TurnaroundAlertsPanel
                  selectedTurnaround={selectedTurnaround}
                  onClose={() => {
                    togglePanels(true, false, false);
                  }}
                />
              </div>
              <div
                className={`right-panel-content${
                  rightPanelMode === MAP_RIGHT_PANEL_MODES.CREW_ALLOCATIONS
                    ? ""
                    : " not-visible"
                }`}
              >
                <AllocationsModule
                  selectedTurnaroundInfo={selectedTurnaround}
                  selectedOperation={selectedOperation}
                  selectedUserUuid={selectedUserUuid}
                  onClose={() => {
                    togglePanels(true, true, false);
                    setSelectedUserUuid(null);
                  }}
                  onAssignedUser={() => {
                    setRefreshSelectedContextRequested(true);
                  }}
                />
              </div>
              <div
                className={`right-panel-content${
                  rightPanelMode === MAP_RIGHT_PANEL_MODES.CREW_ASSIGNMENTS
                    ? ""
                    : " not-visible"
                }`}
              >
                <CrewAssignmentModule
                  selectedTurnaroundInfo={selectedTurnaround}
                  selectedOperation={selectedOperation}
                  selectedUserUuid={selectedUserUuid}
                  onClose={() => {
                    togglePanels(true, true, false);
                    setSelectedUserUuid(null);
                  }}
                  onAssignedUser={() => {
                    setRefreshSelectedContextRequested(true);
                  }}
                />
              </div>
              <div
                className={`right-panel-content${
                  rightPanelMode === MAP_RIGHT_PANEL_MODES.ADD_CREW_MEMBER
                    ? ""
                    : " not-visible"
                }`}
              >
                <AllocationsModule
                  selectedTurnaroundInfo={selectedTurnaround}
                  selectedOperation={selectedOperation}
                  onClose={() => {
                    togglePanels(true, true, false);
                    setSelectedUserUuid(null);
                  }}
                  onAddCrewMember={() => {
                    setRefreshSelectedContextRequested(true);
                  }}
                />
              </div>
            </div>

            {!isNullOrUndefined(selectedTurnaround) && (
              <div className="overlay-panel">
                <div className="overlay-panel-container">
                  <div className="overlay-panel-content turnaround-panel">
                    <div className="context-panel">
                      {isInfoPanelOpen && (
                        <TurnaroundInfoPanel
                          selectedDate={moment()}
                          selectedTurnaroundInfo={selectedTurnaround}
                          selectedTurnaroundInfoSummary={
                            !isNullOrUndefined(selectedTurnaround)
                              ? getByFieldValue(
                                  turnarounds,
                                  "uuid",
                                  selectedTurnaround?.uuid
                                )
                              : null
                          }
                          selectedOperation={selectedOperation}
                          onClose={() => {
                            resetMapSelections(true);
                          }}
                          panelOpen={panelOpen}
                          detailsClickHandler={(value) => {
                            setSelectedOperation(value);
                            togglePanels(true, true, false);
                            document
                              .querySelector(
                                ".map .map-panel .overlay-panel-content"
                              )
                              .scrollTo(0, 0);
                          }}
                          showGateChangeAction={showGateChangeAction}
                          onGateChangeClick={() => {
                            setSelectedOperation(null);
                            togglePanels(true, false, false);
                            setGateChangePanelOpen(true);
                            dispatch({
                              type: "setAnalyticsEvent",
                              value: {
                                eventName:
                                  ANALYTICS_EVENTS.MOVE_AIRCRAFT_BUTTON_CLICK,
                              },
                            });
                          }}
                          showAlertsPanelAction={true}
                          onShowAlertsClick={() => {
                            setSelectedOperation(null);
                            togglePanels(true, false, true);
                            setRightPanelOpen(true);
                            setRightPanelMode(MAP_RIGHT_PANEL_MODES.TIMELINE);
                          }}
                          onUnassignedUserClick={(unassignedUserRecord) => {
                            setSelectedUnassignedUserRecord(
                              unassignedUserRecord
                            );
                            togglePanels(true, false, false);
                          }}
                          showTurnaroundChat={!isInternal}
                          onShowTurnaroundChatClick={() => {
                            dispatch({
                              type: "setSelectedChatRequest",
                              value: {
                                type: CHAT_CATEGORY_TYPES.TURNAROUND,
                                channelId: selectedTurnaround.chatChannelId,
                                selectedTurnaround: selectedTurnaround,
                              },
                            });
                            setTimeout(() => {
                              navigate("/chat");
                            }, 200);
                          }}
                          showUnassignedUserRecords={false}
                        />
                      )}
                      {gateChangePanelOpen && (
                        <div>
                          <GateChangePanel
                            isOpen={gateChangePanelOpen}
                            selectedTurnaround={selectedTurnaround}
                            onCloseHandler={() => {
                              setGateChangePanelOpen(false);
                            }}
                            onSaveHandler={(gateToChange) => {
                              handleAircraftStandChange(gateToChange);
                              dispatch({
                                type: "setAnalyticsEvent",
                                value: {
                                  eventName:
                                    ANALYTICS_EVENTS.MOVE_AIRCRAFT_COMMIT_BUTTON_CLICK,
                                },
                              });
                            }}
                            gateChangeSaving={gateChangeSaving}
                            gates={gates}
                          />
                        </div>
                      )}
                      {unassignedUserPanelOpen && (
                        <UnassignedCrewPanel
                          onBack={() => {
                            togglePanels(true, false);
                            setSelectedOperation(null);
                            setSelectedUnassignedUserRecord(null);
                            setSelectedUserUuid(null);
                            dispatch({
                              type: "setShowOnMap",
                              value: {
                                markerType: MAPINFO_MARKER_TYPE.AIRCRAFT,
                                item: {
                                  registration: selectedTurnaround.registration,
                                },
                              },
                            });
                          }}
                          onClose={() => {
                            resetMapSelections(true);
                            setSelectedUnassignedUserRecord(null);
                            setSelectedUserUuid(null);
                          }}
                          selectedTurnaround={selectedTurnaround}
                          selectedUserUuid={selectedUserUuid}
                          onSelectUser={(userUuid, rightPanelMode) => {
                            setSelectedUserUuid(userUuid);
                            setSelectedVehicleUuid(null);
                            if (!isNullOrUndefined(rightPanelMode)) {
                              setRightPanelMode(rightPanelMode);
                              setRightPanelOpen(true);
                            } else {
                              togglePanels(true, true, false);
                            }
                          }}
                          showActions={false}
                          positions={positions}
                          turnarounds={turnarounds}
                          airportTimezone={airportTimezone}
                          users={usersList}
                        />
                      )}
                      {isDetailsPanelOpen && (
                        <TurnaroundOperationPanel
                          selectedTurnaround={selectedTurnaround}
                          selectedOperation={selectedOperation}
                          selectedVehicleUuid={selectedVehicleUuid}
                          selectedUserUuid={selectedUserUuid}
                          onBack={() => {
                            togglePanels(true, false);
                            setSelectedOperation(null);
                            setSelectedVehicleUuid(null);
                            setSelectedUserUuid(null);
                            dispatch({
                              type: "setShowOnMap",
                              value: {
                                markerType: MAPINFO_MARKER_TYPE.AIRCRAFT,
                                item: {
                                  registration: selectedTurnaround.registration,
                                },
                              },
                            });
                          }}
                          onClose={() => {
                            resetMapSelections(true);
                          }}
                          onSelectVehicle={(vehicleTrackerId) => {
                            setSelectedVehicleUuid(vehicleTrackerId);
                            setSelectedUserUuid(null);
                            togglePanels(true, true, false);
                          }}
                          onSelectUser={(userUuid, rightPanelMode) => {
                            setSelectedUserUuid(userUuid);
                            setSelectedVehicleUuid(null);
                            if (!isNullOrUndefined(rightPanelMode)) {
                              setRightPanelMode(rightPanelMode);
                              setRightPanelOpen(true);
                            } else {
                              togglePanels(true, true, false);
                            }
                          }}
                          onAddCrewMember={() => {
                            setSelectedUserUuid(null);
                            setSelectedVehicleUuid(null);
                            setRightPanelMode(
                              MAP_RIGHT_PANEL_MODES.ADD_CREW_MEMBER
                            );
                            setRightPanelOpen(true);
                          }}
                          airportTimezone={airportTimezone}
                          positions={positions}
                          vehicles={vehicles}
                          users={usersList}
                          turnarounds={turnarounds}
                          showActions={true}
                          showBackButton={true}
                          showAssignCrew={false}
                          showUnassignCrew={false}
                          showTurnaroundChat={!isInternal}
                          onShowTurnaroundChatClick={() => {
                            dispatch({
                              type: "setSelectedChatRequest",
                              value: {
                                type: CHAT_CATEGORY_TYPES.TURNAROUND,
                                channelId: selectedTurnaround.chatChannelId,
                                selectedTurnaround: selectedTurnaround,
                              },
                            });
                            setTimeout(() => {
                              navigate("/chat");
                            }, 200);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {showOverlayPanel && (
              <MapOverlayPanel
                airport={airport}
                flights={flightsToRender}
                users={activeUsersList}
                turnarounds={turnarounds}
                panelMode={mapFilters.mode}
                searchQuery={searchQuery}
                positions={positions}
                showMapInfo={showMapInfo}
                vehicles={vehicles}
                selectedMarker={selectedMarker}
                mapFilters={mapFilters}
                pinMarker={pinMarker}
              />
            )}
            <MapRecenterControl
              showActiveTurnaroundOption={
                !isEmptyList(validMarkersWithinBounds)
              }
              onClick={(zoomPreset) => {
                if (zoomPreset === MAP_ZOOM_PRESETS.OFF) {
                  // Show active turnarounds aka validMarkersWithinBounds
                  setFitToBoundsRequested({
                    fitToMode: FIT_MODES.POINTS,
                    points: validMarkersWithinBounds,
                  });
                } else {
                  setShowMapRadius(zoomPreset);

                  setFitToBoundsRequested({
                    fitToMode: FIT_MODES.AIRPORT,
                  });
                }
              }}
            />
            {showMapInfo && <MapInfo valuePairs={mapInfo} />}
            {showMapLoading && <LoadingIndicator overlay={true} />}
            <div
              ref={mapContainer}
              className={`mapbox-container${isMapLoaded ? " map-loaded" : ""}${
                showMapRoutes ? " map-show-airport" : ""
              }${showMapStands ? " map-show-stands" : ""}`}
            ></div>
          </div>
        </div>
      </div>
      <Keys ctrlKeyActions={ctrlKeyActions} escKeyAction={escKeyAction} />
      <div className="not-visible">
        {isPinMarkerOpen && (
          <PinMarkerModal
            isOpen={isPinMarkerOpen}
            handleClose={() => {
              setIsPinMarkerOpen(false);
            }}
            handleSave={(pinMarkerToUpdate) => {
              dispatch({
                type: "setPinMarker",
                value: pinMarkerToUpdate,
              });
              setIsPinMarkerOpen(false);
            }}
            pinMarker={pinMarker}
          />
        )}
      </div>
    </div>
  );
}

export default Map;
