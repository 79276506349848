import { useEffect, useState } from "react";
import {
  deepCopy,
  getByFieldValue,
  isEmptyList,
  isNullOrUndefined,
  sortByField,
} from "../../utils";
import Checkbox from "../Checkbox";
import TitleBadge from "../TitleBadge";
import {
  isOperationCompleted,
  isOperationInProgress,
  OperationOffsetType,
} from "../../turnaroundUtils";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { useTranslation } from "react-i18next";
import TurnaroundModuleStats from "./TurnaroundModuleStats";
import TurnaroundModuleInfo from "./TurnaroundModuleInfo";
import TurnaroundModuleSummary from "./TurnaroundModuleSummary";
import { getTurnaroundDetails, patchTurnaroundProfile } from "../../api";
import TurnaroundModuleCrewRequirement from "./TurnaroundModuleCrewRequirement";
import TurnaroundModuleGseRequirement from "./TurnaroundModuleGseRequirement";
import SubMenu from "./SubMenu";
import TurnaroundChatHistory from "./TurnaroundChatHistory";

function TurnaroundsModuleConfig(props) {
  const { t } = useTranslation();
  const { turnaroundInfo, timezone, gseTypes } = props;
  const mainContext = useMainContext();
  const dispatch = useMainDispatchContext();
  const { users, turnarounds, positions } = mainContext;
  const [selectedView, setSelectedView] = useState("crew");
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [turnaroundProfileSource, setTurnaroundProfileSource] = useState(null);
  const [operationsList, setOperationsList] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isProfileEditable, setIsProfileEditable] = useState(true);
  const [selectedOverviewTab, setSelectedOverviewTab] = useState("summary");

  // Initialize the editor
  useEffect(() => {
    if (!isNullOrUndefined(turnaroundInfo)) {
      const hasProfile = !isNullOrUndefined(turnaroundInfo?.originalProfile);
      setIsProfileEditable(hasProfile);
      const hasMonitor = !isNullOrUndefined(turnaroundInfo?.originalMonitor);
      const turnaroundProfile = hasMonitor
        ? deepCopy(turnaroundInfo.originalMonitor)
        : deepCopy(turnaroundInfo.originalProfile);
      setTurnaroundProfileSource(turnaroundProfile);
      const operations = !isEmptyList(turnaroundProfile?.turnaroundOperations)
        ? turnaroundProfile?.turnaroundOperations
        : [];
      sortByField(operations, "type.name");
      setOperationsList(operations);

      // Refresh the selected operation if present
      setSelectedOperation((prev) => {
        if (!isNullOrUndefined(prev)) {
          const operationFromMonitor = getByFieldValue(
            turnaroundProfile?.turnaroundOperations,
            "uuid",
            prev.uuid
          );
          return operationFromMonitor;
        } else {
          return null;
        }
      });
    }
  }, [turnaroundInfo]);

  if (!isEmptyList(selectedOperation?.turnaroundRequirements)) {
    sortByField(
      selectedOperation?.turnaroundRequirements,
      "certification.name"
    );
  }

  async function dismissConfirmation() {
    dispatch({
      type: "setConfirmation",
      confirmation: null,
    });
  }

  async function confirmUpdateOperationEnabled(operation, enabled) {
    const message = enabled ? t("enable_operation") : t("disable_operation");
    dispatch({
      type: "setConfirmation",
      confirmation: {
        message: (
          <div className="confirmation-message">
            <div>{message}:</div>
            <span>{operation.name}</span>
          </div>
        ),
        actionOK: () => {
          handleUpdateOperationEnabled(operation, enabled);
        },
        actionCancel: () => {
          dismissConfirmation();
        },
        actionLabel: t("confirm_non_cap"),
        cancelLabel: t("cancel"),
      },
    });
  }

  async function handleUpdateOperationEnabled(operation, enabled) {
    if (isProcessing) return;
    setIsProcessing(true);
    const patchRequest = {
      uuid: turnaroundInfo.originalProfile.uuid,
      operations: [
        {
          uuid: operation.uuid,
          enabled: enabled,
        },
      ],
    };
    const result = await patchTurnaroundProfile(dispatch, patchRequest);
    if (result) {
      await getTurnaroundDetails(dispatch, turnaroundInfo);
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("saved_web", {
          name: turnaroundInfo.combinedFlightName,
        }),
      });
    }
    dismissConfirmation();
    setIsProcessing(false);
  }

  const gseRequirementsToRender = [];
  if (!isEmptyList(selectedOperation?.turnaroundRequirements)) {
    for (let i = 0; i < selectedOperation.turnaroundRequirements.length; i++) {
      const requirement = selectedOperation.turnaroundRequirements[i];
      if (
        !isNullOrUndefined(requirement.certification?.groundVehicleTypeUuid)
      ) {
        gseRequirementsToRender.push(requirement);
      }
    }
  }

  return (
    <div className="turnarounds-module-content">
      <div className="turnarounds-module-config">
        <div className="side-bar">
          <div
            className={`side-bar-item enabled${
              isNullOrUndefined(selectedOperation?.uuid) ? " selected" : ""
            }`}
            onClick={() => {
              setSelectedOperation(null);
            }}
          >
            <div>
              <div>{t("overview")}</div>
            </div>
          </div>
          {!isEmptyList(operationsList) && (
            <div className="side-bar-label">
              <div>{t("operations")}:</div>
            </div>
          )}
          <div>
            {!isEmptyList(operationsList) &&
              operationsList.map((operation) => {
                const inProgress = isOperationInProgress(
                  operation?.status?.statusId
                );
                const isCompleted = isOperationCompleted(
                  operation?.status?.statusId
                );
                return (
                  <div
                    className={`side-bar-item${
                      operation.uuid === selectedOperation?.uuid
                        ? " selected"
                        : ""
                    }${operation.enabled ? " enabled" : ""}`}
                    onClick={() => {
                      const operationFromMonitor = getByFieldValue(
                        turnaroundProfileSource?.turnaroundOperations,
                        "uuid",
                        operation.uuid
                      );
                      setSelectedOperation(operationFromMonitor);
                    }}
                    key={operation.uuid}
                  >
                    <div>
                      <div>{operation.type.name}</div>
                      {operation.enabled && inProgress && (
                        <div className="in-progress">
                          <div>
                            <div className="pulse fadeInLoop"></div>
                          </div>
                          <div>{t("in_progress")}</div>
                        </div>
                      )}
                      {operation.enabled && isCompleted && (
                        <div className="completed">
                          <div>{t("completed")}</div>
                        </div>
                      )}
                    </div>
                    <div>
                      {isProfileEditable && (
                        <Checkbox
                          isChecked={operation.enabled}
                          changeHandler={() => {
                            confirmUpdateOperationEnabled(
                              operation,
                              !operation.enabled
                            );
                          }}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="main-container">
          {isNullOrUndefined(selectedOperation) && (
            <>
              <div className="module-section">
                <div>
                  <TurnaroundModuleInfo
                    selectedTurnaroundInfo={turnaroundInfo}
                    timezone={timezone}
                  />
                </div>
              </div>
              <SubMenu
                menuTitle={t("view")}
                menuItems={[
                  {
                    id: "summary",
                    name: t("summary"),
                  },
                  {
                    id: "chat_history",
                    name: t("chat_history"),
                  },
                ]}
                selectedView={selectedOverviewTab}
                onClick={(value) => {
                  setSelectedOverviewTab(value);
                }}
              />
              {selectedOverviewTab === "summary" && (
                <>
                  {!isNullOrUndefined(turnaroundProfileSource) && (
                    <TurnaroundModuleSummary
                      selectedTurnaroundInfo={turnaroundInfo}
                      gseTypes={gseTypes}
                      onSelectOperation={(operationUuid) => {
                        const operationFromMonitor = getByFieldValue(
                          turnaroundProfileSource?.turnaroundOperations,
                          "uuid",
                          operationUuid
                        );
                        setSelectedOperation(operationFromMonitor);
                      }}
                    />
                  )}
                  {isNullOrUndefined(turnaroundProfileSource) && (
                    <div className="module-section">
                      <div className="empty-state">
                        {t("turnaround_details_not_available")}
                      </div>
                    </div>
                  )}
                </>
              )}
              {selectedOverviewTab === "chat_history" && (
                <>
                  <div className="module-section">
                    <TurnaroundChatHistory
                      selectedTurnaroundInfo={turnaroundInfo}
                    />
                  </div>
                </>
              )}
            </>
          )}
          {!isNullOrUndefined(selectedOperation) && selectedOperation && (
            <>
              <div className="module-section">
                <div>
                  <div className="operation-summary">
                    <div>
                      <div>
                        <h3 className="badged">
                          {selectedOperation.type.name}
                          {false && (
                            <TitleBadge
                              value={`${
                                selectedOperation.enabled
                                  ? t("enabled")
                                  : t("disabled")
                              }`}
                            />
                          )}
                        </h3>
                      </div>
                      <div>
                        <span>
                          <span>
                            {selectedOperation.offsetTypeId ===
                            OperationOffsetType.INBOUND
                              ? t("inbound")
                              : t("outbound")}
                          </span>
                          &nbsp;&middot;&nbsp;
                          <span
                            className={
                              selectedOperation.enabled ? "" : "danger"
                            }
                          >
                            {selectedOperation.enabled
                              ? t("enabled")
                              : t("disabled")}
                          </span>
                        </span>
                      </div>
                    </div>
                    <TurnaroundModuleStats
                      timezone={timezone}
                      selectedOperation={selectedOperation}
                    />
                  </div>
                </div>
              </div>
              <SubMenu
                menuTitle={t("resources")}
                menuItems={[
                  {
                    id: "crew",
                    name: t("crew"),
                  },
                  {
                    id: "gse",
                    name: t("gse"),
                  },
                ]}
                selectedView={selectedView}
                onClick={(value) => {
                  setSelectedView(value);
                }}
              />
              {selectedView === "crew" && (
                <div className="module-section operation-config-container">
                  <div>
                    <div className="operation-config">
                      <div className="operation-requirements">
                        {selectedOperation &&
                          selectedOperation.turnaroundRequirements.map(
                            (requirement) => (
                              <TurnaroundModuleCrewRequirement
                                key={requirement.uuid}
                                turnaroundInfo={turnaroundInfo}
                                selectedOperation={selectedOperation}
                                requirement={requirement}
                                users={users}
                                positions={positions}
                                turnarounds={turnarounds}
                                timezone={timezone}
                                isProfileEditable={isProfileEditable}
                              />
                            )
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedView === "gse" && (
                <div className="module-section operation-config-container">
                  <div>
                    <div className="operation-config">
                      {isEmptyList(gseRequirementsToRender) && (
                        <div className="empty-state">
                          {t("no_gse_requirements")}
                        </div>
                      )}
                      <div className="operation-requirements">
                        {!isEmptyList(gseRequirementsToRender) &&
                          gseRequirementsToRender.map((requirement) => (
                            <TurnaroundModuleGseRequirement
                              key={requirement.uuid}
                              turnaroundInfo={turnaroundInfo}
                              selectedOperation={selectedOperation}
                              requirement={requirement}
                              gseTypes={gseTypes}
                              timezone={timezone}
                              isProfileEditable={isProfileEditable}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default TurnaroundsModuleConfig;
