function SubMenu(props) {
  const { menuTitle, menuItems, selectedView, onClick } = props;
  return (
    <div className="module-submenu">
      <div>
        <div className="module-submenu-label">
          <div>{menuTitle}:</div>
        </div>
        {menuItems &&
          menuItems.map((item) => (
            <div
              key={item.id}
              className={`module-submenu-item${
                selectedView === item.id ? " selected" : ""
              }`}
              onClick={() => {
                onClick(item.id);
              }}
            >
              <div>{item.name}</div>
            </div>
          ))}
        <div></div>
      </div>
    </div>
  );
}

export default SubMenu;
