import { useTranslation } from "react-i18next";
import ScheduledTimeValue from "../Turnarounds/ScheduledTimeValue";
import moment from "moment-timezone";
import { formatTime, isBlank, isNullOrUndefined } from "../../utils";
import { EMPTY_STAND } from "../../constants";
import { ReactComponent as Arrival } from "../../assets/turnarounds/arrival.svg";
import { ReactComponent as Departure } from "../../assets/turnarounds/departure.svg";

function TurnaroundModuleInfo(props) {
  const { t } = useTranslation();
  const { selectedTurnaroundInfo, timezone } = props;
  const mToday = moment().tz(timezone).startOf("day");

  return (
    <div className="turnaround-module-info">
      <div className="turnaround-module-info-stats">
        <div className="turnaround-module-info-row">
          <div>
            <Arrival />
          </div>
          <div className="bigstat">
            <div>{selectedTurnaroundInfo.inboundFlightName}</div>
          </div>
          <InfoStat
            label={t("origin")}
            value={selectedTurnaroundInfo.originAirport}
          />
          <InfoStat
            label={t("gate_in_non_cap")}
            value={
              <ScheduledTimeValue
                actualTime={selectedTurnaroundInfo?.gateIn}
                scheduledTime={selectedTurnaroundInfo?.scheduledGateInTime}
                timezone={selectedTurnaroundInfo.airportTimezone}
                selectedDate={mToday}
              />
            }
          />
          <InfoStat
            label={t("landing")}
            value={formatTime(selectedTurnaroundInfo.landing, timezone, true)}
          />
          <InfoStat
            label={t("current_stand")}
            value={
              !isNullOrUndefined(selectedTurnaroundInfo.snappedStand)
                ? selectedTurnaroundInfo.snappedStand.name
                : EMPTY_STAND
            }
          />
        </div>
        <div className="turnaround-module-info-row">
          <div>
            <Departure />
          </div>
          <div className="bigstat">
            <div>{selectedTurnaroundInfo.outboundFlightName}</div>
          </div>
          <InfoStat
            label={t("destination")}
            value={selectedTurnaroundInfo.destinationAirport}
          />
          <InfoStat
            label={t("gate_out_non_cap")}
            value={
              <ScheduledTimeValue
                actualTime={selectedTurnaroundInfo?.gateOut}
                scheduledTime={selectedTurnaroundInfo?.scheduledGateOutTime}
                timezone={selectedTurnaroundInfo.airportTimezone}
                selectedDate={mToday}
              />
            }
          />
          <InfoStat
            label={t("takeoff")}
            value={formatTime(selectedTurnaroundInfo.takeoff, timezone, true)}
          />
          <InfoStat
            label={t("assigned_dep_stand_abbr")}
            value={
              !isBlank(selectedTurnaroundInfo?.departureStand?.name)
                ? selectedTurnaroundInfo?.departureStand.name
                : EMPTY_STAND
            }
          />
        </div>
      </div>
    </div>
  );
}

function InfoStat(props) {
  return (
    <div className="stat">
      <div>
        <div>
          <span>{props.label}</span>
        </div>
        <div>
          <span>{props.value}</span>
        </div>
      </div>
    </div>
  );
}
export default TurnaroundModuleInfo;
