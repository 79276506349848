import { useMainContext } from "../../MainContext";
import { isNullOrUndefined } from "../../utils";
import ChannelInfo from "./ChannelInfo";

function ChatChannel(props) {
  const { channelInfo, onSelect, timezone } = props;
  const mainContext = useMainContext();
  const { selectedChannelId } = mainContext;
  const isSelected = selectedChannelId === channelInfo.channelId;
  const hasUnreadMessages = channelInfo.hasUnreadMessages;

  return (
    <div
      className={`chat-side-panel-item channel${isSelected ? " selected" : ""}`}
      onClick={() => {
        if (!isNullOrUndefined(onSelect)) {
          onSelect();
        }
      }}
    >
      <div className="chat-channel">
        <div>
          <div className="chat-channel-name">
            <ChannelInfo
              channelInfo={channelInfo}
              isSelected={isSelected}
              timezone={timezone}
            />
          </div>
          <div>
            <div
              className={`unread-indicator${
                hasUnreadMessages ? " active" : ""
              }`}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatChannel;
